import { useEffect } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { autorun, configure, extendObservable, reaction, set as mset, toJS } from 'mobx'

import { appConfig, SESSION_TIMEOUT, SESSION_TIMESTAMP_KEY } from '@Config'
import dataCountry from '@Configs/json/country.json'

import {
    staticFooter,
    checkOutStore,
    store,
    storeManager,
    devTools
} from '@Stores/MainStore'
import { storeHomePage } from '@Stores/StoreHomePage'
import { storeNotification } from '@Stores/StoreNotification'
import { language } from '@Language'

import { Country } from '@Configs/Country'
import {
    getParameterByName,
    isMaintenanceMode,
    checkHost,
    getDevelopmentID
} from '@GlobalHelpers'

import { isNothing, defaults, isSomething, someOf, toBool } from '@Utils/Utils'
import { updateTranslation } from '@Utils/Translator'
import { logn, loge, logi, logw } from '@Utils/PikaLog'

import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import get from 'lodash/get'
import set from 'lodash/set'
import keys from 'lodash/keys'
import pick from 'lodash/pick'
import delay from 'lodash/delay'

import { configResponsive } from 'ahooks'
import loadable from '@loadable/component'

import Raven from '@Raven'
import {
    getProvinceListHK,
    getNotifications,
    getSiteVersion,
    getBannerFromAws,
    getProvinceList,
    getBannerV2FromAws
} from '@Services/Network'

import { useBowser } from './components/utils/Bowser'
import { storeBowser } from '@Stores/StoreBowser'
import { getQueryString, hasQueryString } from '@Configs/ConfigsHeader'

import Redirect from '@Components/Redirect'
import history from '@Components/History'
import mobiscroll from '@mobiscroll/react'

import { Layout } from '@Uflex'
import { useProductSource } from '@Services/use/ProductSource'
import { useFirebaseApp } from '@Firebase/Firebase'
// import { initFirebaseCart } from '@Firebase/FirebaseCart'
import { initIoCart } from '@io/IoCart'

import { first, last, lowerCase } from 'lodash'
import { StorageExtend, SessionExtend } from '@Utils/StorageManager'
import StoreMenu from '@Stores/StoreMenu'
import StoreAuth from '@Stores/User/StoreAuth'
import { useAuthorization } from '@Components/Authorization'
import { storeAPI } from '@Stores/StoreAPI'
import StoreUser from '@Stores/User/StoreUser'
import { createToken } from '@Services/use/FetchData'
import StoreCountry from '@Stores/StoreCountry'
import kspay from './modules/kspay/kspay.js'
import { constants_kr } from './constants'
import { GlobalSocialLogin } from './pages/Global'


configure({
    enforceActions: 'never'
})

mobiscroll.settings = {
    theme: 'ios',
    themeVariant: 'light'
}

const reactionOptions = { fireImmediately: true }

/**
 * log: PikaLog.js의 logi, logn, 등등을 사용해서 로그를 표현함.
 */
const App = observer(props => {
    const localStore = useLocalObservable(() => ({
        isLoading: true
    }))

    useFirebaseApp()
    useProductSource()
    useAuthorization()

    // * -----------------------
    // * START -> Bowser
    const detector = useBowser()

    useEffect(() => {
        if (detector) {
            storeBowser.model = detector.model
            storeBowser.type = detector.type
            storeBowser.os = detector.os
        }
    }, [detector])
    // * END <- Bowser
    // * -----------------------

    useEffect(() => {
        logn(
            `Host: [${checkHost()}] | DEV-ID: [${defaults(
                getDevelopmentID(),
                'None'
            )}]`
        )

        if (props.showCountryPortal) {
            //
        } else {
            // Forced reloading after not reponsed for 40 seconds.
            const timeoutForceReload = 40
            delay(() => {
                if (localStore.isLoading) {
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.reload()
                    throw `Reloading: App was not responsed after ${timeoutForceReload} seconds.`
                } else {
                    logw(
                        `Timeout Reached ${timeoutForceReload}s: No need forced reloading...`
                    )
                }
            }, timeoutForceReload * 1000)

            delay(() => {
                logw('Timeout Reached 60s: Init GA and Pixel...')

                let GACode =
                    StoreCountry.GACode[lowerCase(StoreCountry.country.country)]
                let GACodeAll = 'G-GM2PVE274J' //'UA-119346265-7'
                if (/ushop2021/.test(window.location.href)) {
                    GACode =
                        StoreCountry.GACode2021[
                        lowerCase(StoreCountry.country.country)
                        ]
                    GACodeAll = 'G-GM2PVE274J'//'G-8H2T7E7LQB'
                }
                // * START -> Google Analytics
                ReactGA.initialize([
                    {
                        trackingId: GACodeAll,
                        debug: false
                    },
                    {
                        trackingId: GACode,
                        gaOptions: {
                            name: lowerCase(StoreCountry.country.country)
                        },
                        debug: false
                    }
                ])
                ReactGA.pageview(
                    window.location.pathname + window.location.search
                )
                ReactGA.ga(
                    lowerCase(StoreCountry.country.country) + '.send',
                    'pageview',
                    { page: window.location.pathname + window.location.search }
                )
                // * END -> Google Analytics
                // * -----------------------
                // * START -> Facebook Pixel
                const options = {
                    autoConfig: true,
                    debug: false
                }
                ReactPixel.init(
                    appConfig.FBPixelCode[
                    lowerCase(StoreCountry.country.country)
                    ],
                    options
                )
                // ReactPixel.pageView()
                // * END <- Facebook Pixel
                // * -----------------------
            }, 60 * 1000)

            // ** Start Initiate startup API **
            startInitiateAPIs()

            // ** Delared resposive breakpoints.
            configResponsive({
                xs: 0,
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
                xxl: 1600
            })
        }

        // Simulate Maintenance
        if (hasQueryString('mtn') || hasQueryString('maintenance')) {
            devTools.isSimulateMaintenance = true
        }
        // Reveal dictionary keys
        if (
            hasQueryString('dic') ||
            hasQueryString('dict') ||
            hasQueryString('dictionary')
        ) {
            devTools.isShowDictionaryLabel = true
        }
        // Enable Development Log on Production stage
        if (hasQueryString('log')) {
            devTools.isShowDevLog = true
        }

        // country data
        store.countryData = dataCountry

        window.document.addEventListener('message', function (e) {
            if (window.ReactNativeWebView !== undefined) {
                localStorage.setItem('isWebview', true)
                store.isWebview = true
                store.latestWebview = true
            }
        })
        // this is working on iOS
        window.addEventListener('message', function (e) {
            if (window.ReactNativeWebView !== undefined) {
                localStorage.setItem('isWebview', true)
                store.isWebview = true
                store.latestWebview = true
            }
        })

        if (hasSessionTimeout()) {
            setCustomSessionTimestamp()
        } else {
            StorageExtend.set(
                new Date().getTime(),
                `development.${SESSION_TIMESTAMP_KEY}`
            )
        }
        function hasSessionTimeout() {
            return (
                isSomething(getQueryString().sessionTimeout) &&
                !isNaN(parseFloat(getQueryString().sessionTimeout))
            )
        }
        function setCustomSessionTimestamp() {
            const customTimestamp =
                new Date().getTime() -
                (SESSION_TIMEOUT -
                    parseFloat(getQueryString().sessionTimeout) * 1000 * 60)
            StorageExtend.set(
                customTimestamp,
                `development.${SESSION_TIMESTAMP_KEY}`
            )
            const now = new Date()
            now.setTime(customTimestamp)
            logn(`⏱ SET SESSION TIMESTAMP = ${now.toLocaleString()}`)
        }

        reaction(
            () => StoreAuth.isInitilized,
            (init, pInit, rAuth) => {
                if (init === true && pInit === false) {
                    // check login type first
                    if (StoreAuth.isAutoLogin) {
                        reaction(
                            () => StoreAuth.autoLoginStatus,
                            (status, pStatus, rAutoLogin) => {
                                if (status && isNothing(pStatus)) {
                                    if (StoreAuth.isAutoLoginSuccess) {
                                        if (
                                            getQueryString().page &&
                                            getQueryString().page === 'products'
                                        ) {
                                            history.push('/catproduct/all')
                                        } else {
                                            console.log('app>>>>>>>>>>>>>>>>>')
                                            history.push('/menu')
                                        }
                                    } else if (StoreAuth.isAutoLoginFailed) {
                                        history.push('/login')
                                    }
                                    finalizeRequests()
                                    rAutoLogin.dispose()
                                    rAuth.dispose()
                                }
                            },
                            reactionOptions
                        )
                    } else {
                        finalizeRequests()
                        rAuth.dispose()
                    }
                } else {
                    logn(`🔐 Pending for authorization process...`)
                }
            },
            reactionOptions
        )
    }, [])

    const finalizeRequests = () => {
        // ** [API] Connect with Firebase cart **
        // initFirebaseCart()
        initIoCart()
        // ** [API] Get all languages dictionary **
        loadLanguagesDictionary()
        const baId = StoreUser.getID()
        const token_check = baId ? createToken(baId) : undefined

        if (StoreAuth.isAuthorized) {
            getBannerV2FromAws(
                (response, status) => {
                    // getBannerFromAws((response, status) => {
                    if (status) {
                        storeHomePage.banner = response
                    }
                },
                baId,
                token_check
            )
        }
        
        autorun(r => {

            if (storeManager.isFooterStaticDone) {
                logn('✅ 🦶 The super-duper static Footer is done.')
                if (isSomething(language.dictionary)) {
                    logn('✅ 🏠 Our lovely home is ready.')
                    localStore.isLoading = false
                }
            }


            r.dispose()
        })
    }

    const allocateCity = data => {
        // ** Do this after all province API done
        if (isSomething(data)) {
            store.listProvinces = data

            let keyProvinceNative, keyProvinceEnglish
            if (Country.isHongKong()) {
                keyProvinceNative = 'PROVINCE_NAME'
                keyProvinceEnglish = 'PROVINCE_NAME_ENG'
            } else {
                keyProvinceNative = 'city_native'
                keyProvinceEnglish = 'city_roman'
            }
            data.map(province => {
                const englishTitle = defaults(
                    province[keyProvinceEnglish],
                    ''
                ).trim()
                const nativeTitle = defaults(
                    province[keyProvinceNative],
                    ''
                ).trim()
                mset(store.listProvincesNative, { [englishTitle]: nativeTitle })
                mset(store.listProvincesEnglish, {
                    [nativeTitle]: englishTitle
                })
                return null
            })
        }
    }

    /** Start initiate all basic need APIs. */
    const startInitiateAPIs = () => {
        const C3 = StoreCountry.Country3()

        // * [URL Token and Href]
        const token = getParameterByName('token')
        const href = getParameterByName('href')
        if (token && href) {
            // override customer href and token if come from AO
            const customerHref = `https://hydra.unicity.net/v5a/customers/${href}`
            localStorage.setItem('customerHref', customerHref)
            localStorage.setItem('customerToken', token)
            localStorage.setItem('user-href', customerHref)
            localStorage.setItem('user-token', token)
            logn(`Use token and href from url.\nToken: ${token}\nHref: ${href}`)
        }

        // TODO: later check if this condition is deprecated
        if (sessionStorage.getItem('dtoken')) {
            let dtoken = JSON.parse(sessionStorage.getItem('dtoken'))
            checkOutStore.dToken = dtoken
        }

        // getBannerFromAws((response, status) => {
        //     if (status) {
        //         storeHomePage.banner = response
        //     }
        // })

        // getBannerV2FromAws((response, status) => {
        //     if (status) {
        //         storeHomePage.banner = response
        //     }
        // })

        Raven.getHomePageData(C3)
            .then(response => {
                storeHomePage.topSection = response.topSection
                storeHomePage.loginSection = response.loginSection
                storeHomePage.serviceSection = response.serviceSection

                // speed-up bg images load with regular cache
                if (storeHomePage.getBannerType() === 'image') {
                    const newDiv = document.createElement('div')
                    newDiv.style = 'display: none;'
                    newDiv.innerHTML += `
                <div style="background-image: url(${storeHomePage.topSection.backgroundImage.url['english']})"></div>
                <div style="background-image: url(${storeHomePage.topSection.backgroundImage.url['native']})"></div>
                `
                    document.body.appendChild(newDiv)
                }
            })
            .catch(error => {
                console.log('getHomePageData error=>', error)
            })

        // ** [API] Get Static Footer **
        // logtime('Update Footer Static').start()
        Raven.getFooter()
            .then(response => {
                setStaticFooter(response)
                // StorageExtend.setToPublic(response, 'footer-static')
                StoreMenu.setMenuHeader(response.footer.header_menu)
                storeManager.isFooterStaticDone = true
                // logtime('Update Footer Static').end()
            })
            .catch(error => {
                loge('Could not load Footer API.')
            })

        // ** [API] Get provinces list **
        if (someOf(StoreCountry.Country2(), ['TH', 'PH', 'ID', 'TW', 'MY'])) {
            Raven.getCitySearch(C3)
                .then(response => {
                    if (response.success) {
                        allocateCity(response.data)
                    }
                })
                .catch(() => { })
        }

        // ** [API] Get provinces list for Hongkong **
        if (Country.isHongKong()) {
            getProvinceListHK((response, status) => {
                if (status) {
                    response = response.map(item => {
                        const pickedData = pick(item, [
                            'PROVINCE_NAME',
                            'PROVINCE_NAME_ENG'
                        ])
                        return pickedData
                    })
                    allocateCity(response)
                }
            })
        }

        allocateCity()

        // ** [API] Get banks name list for Thailand **
        /* if (!(sessionStorage.getItem('banknames')) && Country.isThailand()) {
            getBanknameList((res, status) => {
                sessionStorage.setItem('banknames', res)
            }, {
                strData: JSON.stringify({
                    "action": "getBankname",
                    "country": StoreCountry.Country3,
                })
            })
        } */

        // ** [API] Get Notifications **

        // getNotifications((response, status) => {
        //     if (status) {
        //         const dataNotification = get(response, 'data[0]')
        //         if (isSomething(dataNotification)) {
        //             if (get(dataNotification, 'product_image', false)) {
        //                 const preload = new Image()
        //                 preload.src = get(dataNotification, 'product_image')
        //             }
        //             storeNotification.content = dataNotification
        //             storeNotification.isLoaded = true
        //         }
        //     }
        // }, StoreCountry.Country3)
    }

    /** Set versioning number from database. Clear **localStorage** when it's changed. */
    const processSiteVersion = (source, response) => {
        const localStorageTag = `version['${source}']`
        const result = JSON.parse(response)
        if (toBool(result.success)) {
            const targets = result.target
            const version = result.version
            // a function for force reload and clear cache
            const forceReload = newVersion => {
                localStorage.clear()
                sessionStorage.clear()
                if (isNothing(newVersion) === false) {
                    StorageExtend.setToCountry(newVersion, localStorageTag)
                }
                window.location.reload()
                throw ': Force reload is required, please wait.'
            }
            // a function for check global versioning
            const checkGlobalReset = () => {
                // logi('[Versioning] > Check for global versioning.')
                if (isNothing(StorageExtend.getFromCountry(localStorageTag))) {
                    if (isNothing(result.source) === false) {
                        StorageExtend.setToCountry(version, localStorageTag)
                    }
                } else {
                    if (isNothing(result.source) === false) {
                        if (
                            StorageExtend.getFromCountry(localStorageTag) !==
                            version
                        ) {
                            forceReload(version)
                        }
                    }
                }
            }

            // api return targets versioning
            if (isSomething(targets)) {
                // user is logged in
                if (StoreAuth.isAuthorized) {
                    const currentUserID = StoreAuth.id
                    const listTargets = targets.split(',')
                    listTargets.map(userID => {
                        // matched id
                        if (`${userID}` === `${currentUserID}`) {
                            logi(
                                '[Versioning] > Found target versioning:',
                                `${userID}<U-V>${currentUserID}`
                            )
                            // send target back to api
                            getSiteVersion(source, currentUserID, response => {
                                const result = JSON.parse(response)
                                if (toBool(result.success)) {
                                    // ready to reload
                                    forceReload()
                                } else {
                                    checkGlobalReset()
                                }
                            })
                        }
                    })
                } else {
                    // no user logged in, check global version
                    checkGlobalReset()
                }
            } else {
                // no targets versioning, check global version
                checkGlobalReset()
            }
        } else {
            StorageExtend.setToCountry(0, localStorageTag)
        }
    }

    /** Set static footer to MobX observable object. */
    const setStaticFooter = data => {
        const footerData = data.footer
        staticFooter.country = footerData.country
        staticFooter.footerApp = footerData.app
        staticFooter.footerContact = footerData.contact
        staticFooter.footerHours = footerData.hours
        staticFooter.footerSocial = footerData.social
        staticFooter.footerQuickLink = footerData.quick_link
        staticFooter.footerHelp = footerData.help
        staticFooter.footerGeneral = footerData.general
        staticFooter.footerFeedBack = footerData.feedback
        staticFooter.footerDisclaimer = footerData.disclaimer
        staticFooter.footerBanner = footerData.banner
        staticFooter.footerPromotion = footerData.promotion
        staticFooter.footerVideo = footerData.video
        staticFooter.footerOrder = footerData.order
        staticFooter.footerTextTitle = footerData.title_product_section
        staticFooter.footerMessenger = footerData.messenger
        staticFooter.footerMainPic = footerData.main_pic
        staticFooter.footerDisplaySections = footerData.display_sections
        staticFooter.footerTCOrder = footerData.term_and_condition_order
        staticFooter.footerTCEnroll = footerData.term_and_condition_enroll
        staticFooter.footerMisc = footerData.misc
        staticFooter.footerTermsConditions = footerData.terms_and_conditions
        staticFooter.footerPrivacyPolicy = footerData.privacy_policy
    }

    const loadLanguagesDictionary = () => {
        const languagesList = ['KR', 'EN']
        logn('🌐 Connecting with Language:', toJS(languagesList))

        // 1. Check the primary language first
        let primeLanguageCode = language.primary
        if (isSomething(languagesList)) {
            primeLanguageCode = first(languagesList)
        } else {
            primeLanguageCode = 'EN' // handled null error
            loge('🌐 Languages list is empty, set English by default.')
        }
        // set primary language to store
        language.primary = primeLanguageCode

        // NOTE: should improved this later (does NOT support multiple native language).
        if (primeLanguageCode !== 'EN') {
            language.native = primeLanguageCode
        } else {
            language.native = last(languagesList)
        }
        logn(
            `🌐 Language: Native [${language.native}] | Primary [${language.primary}]`
        )

        const restoreLanguageCode = StorageExtend.getFromPersonal(
            'language',
            undefined,
            { fromCustomer: StoreUser.getID(), fallbackToPublic: false }
        )

        if (isSomething(restoreLanguageCode)) {
            logn(`🌐 Found personal language saved. [${restoreLanguageCode}]`)
            language.current = restoreLanguageCode
        } else {
            logn(`🌐 Personal language NOT found. [${restoreLanguageCode}]`)
            language.current = primeLanguageCode
        }

        // 2. Get all dictionary from API
        let resultLanguagesList = ''
        if (Array.isArray(languagesList)) {
            if (languagesList.length === 0)
                throw 'languagesList should not be empty.'
            if (languagesList.length > 1) {
                languagesList.forEach(lang => {
                    resultLanguagesList = resultLanguagesList.concat(lang, ',')
                })
                resultLanguagesList = resultLanguagesList.substring(
                    0,
                    resultLanguagesList.length - 1
                )
            } else {
                resultLanguagesList = languagesList[0]
            }
        } else {
            resultLanguagesList = languagesList
        }

        if (sessionStorage.getItem('redirect-language')) {
            language.current = sessionStorage.getItem('redirect-language')
            sessionStorage.removeItem('redirect-language')
        }

        // restore last dictionary from local storage
        let localDictionary = SessionExtend.getFromCountry('dictionary')
        if (isSomething(localDictionary)) {
            localDictionary.KR = constants_kr
            language.dictionaries = localDictionary
            if (isSomething(language.dictionaries)) {
                language.dictionary = language.dictionaries[language.current].KR
                updateDictionary(resultLanguagesList)
            }
        } else {
            // if local storage for dictionary not exists, let's wait while loading from API
            language.currentRequestAttempt = 0
            requestDictionary(resultLanguagesList)
        }
    }

    const requestDictionary = languageList => {
        // Raven.getTranslations(languageList)
        //     .then(response => {
        //         response.KR = constants_kr
        //         const createDictionary = rawDictionary => {
        //             const temp = {}
        //             keys(rawDictionary).map(label => {
        //                 set(temp, label, get(rawDictionary, label))
        //             })
        //             return temp
        //         }
        //
        //         // set all dictionary to store
        //         language.dictionaries = {}
        //         keys(response).map(languageCode => {
        //             extendObservable(language.dictionaries, {
        //                 [languageCode]: createDictionary(response[languageCode])
        //             })
        //         })
        //
        //         SessionExtend.setToCountry(language.dictionaries, 'dictionary')
        //         // update language setting if footer is ready.
        //         if (storeManager.isFooterStaticDone) {
        //             updateTranslation(language.current)
        //         }
        //     })
        //     .catch(() => {
        //         if (
        //             language.currentRequestAttempt < language.maxRequestAttempt
        //         ) {
        //             language.currentRequestAttempt++
        //             requestDictionary()
        //         } else {
        //             language.currentRequestAttempt = 0
        //         }
        //     })
    }

    const updateDictionary = resultLanguagesList => {
        // logtime('Update Restore Language').start()
        Raven.getTranslations(resultLanguagesList)
            .then(response => {
                response.KR = constants_kr

                const createDictionary = rawDictionary => {
                    const temp = {}
                    keys(rawDictionary).map(label => {
                        set(temp, label, get(rawDictionary, label))
                    })
                    return temp
                }

                // update dictionary to store
                language.dictionaries = {}
                keys(response).map(lang => {
                    extendObservable(language.dictionaries, {
                        [lang]: createDictionary(response[lang])
                    })
                })

                language.dictionary = language.dictionaries[language.current].KR

                SessionExtend.setToCountry(language.dictionaries, 'dictionary')
                updateTranslation(language.current)
                // logtime('Update Restore Language').end()
            })
            .catch(() => { })
    }

    return (
        <Layout h100={props.showCountryPortal}>
            {(() => {
                if (props.showCountryPortal) {
                    return <DynamicRegionPortal showCountryPortal />
                } else {
                    if (localStore.isLoading || storeAPI.login.isRedirecting) {
                        return <Redirect />
                    } else {
                        if (isMaintenanceMode()) {
                            return <DynamicMaintenance />
                        } else {
                            return <DynamicAclWrapper />
                        }
                    }
                }
            })()}
        </Layout>
    )
})

const DynamicMaintenance = loadable(
    () => import('./components/Home/Maintenance'),
    { fallback: <Redirect /> }
)
const DynamicAclWrapper = loadable(
    () => import('./components/acl/AclWrapper'),
    { fallback: <Redirect /> }
)
const DynamicRegionPortal = loadable(() =>
    import('./components/SelectCountry/SelectCountry')
)

export default App
