import Raven from '../../services/Raven'
import {
    getCountryCode3,
    apiURL,
    convertCountryString,
    getCountry
} from '../GlobalHelpers'
import { isNumber } from '../utils/Utils'
import Axios from 'axios'
import { store } from '../../stores/MainStore'
import StoreAuth from '@Stores/User/StoreAuth'
import StoreCountry from '@Stores/StoreCountry'

const create = (baId, now = new Date()) => {
    const date = now.getUTCDate()
    const weekDay = now.getUTCDay() + 1
    const modeWeekDay = (date % weekDay) + 1
    const hash = baId
        .toString()
        .split('')
        .map(c => parseInt(c) % modeWeekDay)
        .join('')
    return `${hash}${weekDay}${date}`
}

export const EditInfo = (callback, fullUrlWithHref, data, token) => {
    const headers = {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
    }

    Raven.post(fullUrlWithHref, data, { headers: headers })
        .then(data => {
            callback(true, data)
        })
        .catch(error => {
            callback(false, error)
        })
}

export const GetTinyDataCustomer = (callback, baId) => {
    const url = apiURL.expandCustomer + baId + '?token=' + create(baId)

    Axios.get(url)
        .then(data => callback(true, data.data))
        .catch(error => callback(false, error))
}

export const GetGenealogyData = (baId, noCache, token, country) => {
    let customerId = baId

    if (localStorage.getItem('mockup-user-id')) {
        customerId = localStorage.getItem('mockup-user-id')
    } else {
        customerId = baId
    }


    const url = 'https://member-calls2.unicity.com/etlV2/genealogy'
                //  https://member-calls2.unicity.com/etlV2/genealogy?baId=15745082&ushopCountryCode=THA'
    const params = {
        baId: customerId,
        // token: create(customerId),
        ushopCountryCode: country || StoreCountry.country.short,
        // countryCode: 'KOR'
        // sortMonth: 6
    }

    if (noCache) {
        params.byPassCache = 1
    }

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${StoreAuth.GetToken()}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }

    // Axios.get(url, { params: params, headers: headers })
    //     .then(data => callback(true, data.data))
    //     .catch(error => callback(false, error))

    return Axios.get(url, { params: params, headers: headers })
}

export const GetProfileData = (callback, baId, byPassCache, customerToken) => {
    const url = `https://member-calls2.unicity.com/adapter/etl/onself?baId=${baId}&token=${create(
        baId
    )}${byPassCache ? '&byPassCache=1' : ''}`

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${customerToken}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }

    if (baId === '2970466') {
        localStorage.setItem('mockup-user-id', baId)
    }

    Axios.get(url, { headers: headers })
        .then(data => callback(true, data.data))
        .catch(error => callback(false, error))
}

export const GetOrderHistories = (callback, baId, periodStart, periodEnd, token, byPassCache = 0) => {

    const expands = ['orderHistory']

    // const params = {
    //     expand: 'orderHistory',
    //     expandOrderHistory: 'order,rma',
    //     dateCreated: `[${periodStart}|${periodEnd}]`,
    //     customer: 'me|sponsoredCustomers?type=Customer',
    //     customerHref: href
    // };
    const isDummy = localStorage.getItem("isDummy")
    const params = {
        baId: baId,
        ushopCountryCode: getCountryCode3(),
        ...(isDummy ? { isDummy } : {}),
    }

    if(byPassCache!==0) {
        params.byPassCache = byPassCache
    }


    let url = `https://member-calls2.unicity.com/etlV2/ordersHistory`;

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${JSON.parse(localStorage.getItem('login-extends')).korea.token}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    };

    Raven.get(url, params, { headers: headers })
        .then((data) => {
            callback(true, data)
        })
        .catch((error) => {
            callback(false, error)
        });
}
