// import * as firebase from 'firebase'
import { shopStore } from '../../stores/MainStore'
import { updateCartInfo } from '../ShoppingHelper'
import { shoppingClass } from '../shopping/ShoppingClass'
import { saveCartStorage } from '../utils/Customer'
import { someOf, isSomething } from '../utils/Utils'
export function  AddValueItem(configs) {

    let productName
    let onProduct = JSON.parse(JSON.stringify(configs['dataSource']))
    // Storage Cart
    let fireCart = {}
    let storageCart = []
    let type = configs['type'] !== undefined ? configs['type'] : 'add'
    let value = configs['qty'] !== undefined ? parseInt(configs['qty']) : undefined
    let isPowerCart = configs['cart'] === undefined ? false : true
    let multiple = configs['multiple'] === undefined ? false : true
    let id = configs['productId']
    let hasItem = false
    let btnDisabled = null
    // console.log(id);
   
    // let product = onProduct.find(item => item.product_id = id);
    // if(product){
    //     productName = product.post_title
    //     product.qty = parseInt(product.qty)
    //     if(type === "add"){
    //         product.qty = +product.qty
    //     }else if(type === "remove"){
    //         if (product.qty > 0) {
    //             product.qty = product.qty
    //         }else{
    //             product.qty = 0;
    //         }
    //     }else if(type === "removeAll"){
    //         product.qty = 0;
    //     }else {
    //         if (type === 'single') {
    //             if (value !== undefined) {
    //                 product.qty += value
    //             } else {
    //                 product.qty = +product.qty
    //             }
    //         } else {
    //             if (value !== undefined) {
    //                 product.qty = value
    //             } else {
    //                 product.qty = +product.qty
    //             }
    //         }
    //     }
    // }
    
    // let product_qty = onProduct.filter(item => item.qty > 0);
    // product_qty.map((p, i) => {
    //     if(type === 'clearCart'){
    //         p.qty = 0
    //         hasItem = false
    //     }else{
    //         fireCart[p.product_id] = {}
    //         fireCart[p.product_id].id = p.product_id
    //         fireCart[p.product_id].qty = p.qty
    //         storageCart.push({
    //             id: p.product_id,
    //             qty: p.qty
    //         });
    //         hasItem = true
    //     }
    // })
    // if (configs['storage']) {

    //     updateCartInfo(configs['userId'], fireCart);
    // }
    // saveCartStorage(storageCart)

    // return {
    //     dataSource: onProduct,
    //     productName: productName
    // }
    

    
    onProduct.map((b, i) => {
        if (b.product_id === id) {
            productName = b.post_title
            b.qty = parseInt(b.qty)
            
            if (type === 'add') {
                
                if(b.qty < 999) {
                    b.qty = ++b.qty
                }
            } else {
                if (type === 'removeAll') {
                    b.qty = 0
                    console.log(b)
                } else if (type === 'remove') {
                    if (b.qty > 0) {
                        b.qty = --b.qty
                    }  
                    if(b.qty <= 0) {
                        b.qty = 0
                    }
                } else {
                    if (type === 'single') {
                        if (value !== undefined) {
                            b.qty += value
                        } else {
                            b.qty = ++b.qty
                        }
                    } else {
                        if (value !== undefined) {
                            b.qty = value
                        } else {
                            b.qty = ++b.qty
                        }
                    }
                }
            }

            b.btnDisabled = btnDisabled
        }

        if (type === 'clearCart') {
            b.qty = 0
            hasItem = false
        }
        if ((type === 'add') || (type === 'remove')) {
            if (b.qty > 0) {
            
                fireCart[b.product_id] = {}
                fireCart[b.product_id].id = b.product_id
                fireCart[b.product_id].qty = b.qty
                storageCart.push({
                    id: b.product_id,
                    qty: b.qty
                });
                hasItem = true

            }
        }
        return b
    })
    if (configs['storage']) {
        // firebase.database()
        //     .ref('carts_web/' + configs['userId'])
        //     .set(fireCart);
        
        updateCartInfo(configs['userId'], fireCart);
    }
    // saveCartStorage(storageCart)

    return {
        dataSource: onProduct,
        productName: productName
    }
}

export function CalculateCart(products, priceLevel = '') {
    let { total, totalPv, passCheckOut } = shopStore

    // console.log("CalculateCart1", passCheckOut, total, totalPv)

    if (passCheckOut === false && products != null) {
        total = 0
        totalPv = 0
        let dToken = shoppingClass

        products.map((item) => {
            if (isSomething(item)) {
                if (item.qty > 0) {
                    let resultPrice = parseFloat(item.member_price) * item.qty
                    if (isSomething(priceLevel)) {
                        if (someOf(priceLevel, ['member', 'retail', 'wholesale', 'preferred', 'employee'])) {
                            resultPrice = parseFloat(item[`${priceLevel}_price`]) * item.qty
                        }
                    }
    
                    total += resultPrice
                    totalPv += (parseInt(item.pv) * item.qty)
                }
            }
        })

        // console.log("CalculateCart2", passCheckOut, total, totalPv)
    }

    return {
        total: total,
        totalPv: totalPv
    }
}

export function CalculateCartWithPriceLevel(products, priceLevel) {
    let { total, totalPv, passCheckOut } = shopStore

    // console.log("CalculateCart1", passCheckOut, total, totalPv)

    if (passCheckOut === false && products != null) {
        total = 0
        totalPv = 0
        products.map((v, k) => {
            if (v.qty > 0) {
                // console.log(v.item_code, v.qty, v.member_price, v.pv)
                total += ((v[priceLevel] === '' ? 0: parseFloat(v[priceLevel])) * v.qty)
                totalPv += (parseInt(v.pv) * v.qty)
            }
        })

        // console.log("CalculateCart2", passCheckOut, total, totalPv)
    }

    return {
        total: total,
        totalPv: totalPv
    }
}