import { enrollStore, defaultEnrollStore, checkOutEnrollStore, defaultCheckOutEnrollStore} from '../stores/MainStore'
import { shopStore, defaultShopStore, checkOutStore, defaultCheckOutStore, store } from '../stores/MainStore'
import { shippingAddress, appConfig } from './config'
import { numeralFormat, isCountryHongkong } from './GlobalHelpers'
import { getCountryCode, getDefaultCountry } from './GlobalHelpers'
import { clearLocalShoppingCart, apiURL } from './ShoppingHelper';
import { isString, eliminateJSONEnemy, testArray, defaults } from './utils/Utils';
import { shippingMethod } from './utils/ShippingMethod';
import { storeEasyShop } from '../stores/StoreEasyShop';

export var COUNTRY_CODE = getCountryCode()

export function onLeaveEnroll() {
    if (enrollStore.isCartFromShopping === true) {
        // clearLocalShoppingCart()
    }
    Object.keys(enrollStore).map((a) => {
        enrollStore[a] = defaultEnrollStore[a]
    })



    shopStore.passCheckOut = false
    shopStore.enrollTotal = 0
    shopStore.enrollTotalPv = 0
    localStorage.removeItem('referral')

    let storageCart = [];
    localStorage.setItem('storageEnrollCart', JSON.stringify(storageCart))
    
    let autoShipCart = localStorage.getItem('storageAutoshipEnrollCart')
    if(autoShipCart && localStorage.getItem('payment-response')) {
        autoShipCart = JSON.parse(autoShipCart)
        enrollStore.enrollProductSource.map((v) => {
            autoShipCart.map((b) => {
                if(v.item_code === b.id) {
                    v.qty = b.qty
                }
            })
            return v
        })
        enrollStore.autoshipEnrollCart = enrollStore.enrollProductSource
    }

    // let onProduct = JSON.parse(JSON.stringify(enrollStore.enrollCart))
    // onProduct.map((b, i) => {
    //     b.qty = 0
    //     return b
    // })
    // enrollStore.enrollState = false
    // enrollStore.enrollCart = onProduct
}

export function getEnrollCartItemValue(item_code, name) {
    let result = 0
    if (name === 'item_total_pv') {
        enrollStore.enrollCart.map((v, k) => {
            if (v.product_id === item_code) {
                result = parseInt(v.pv) * parseInt(v.qty)
            }
        })
    }
    if (name === 'price_per_unit') {
        enrollStore.enrollCart.map((v, k) => {
            if (v.product_id === item_code) {
                result = numeralFormat(v.member_price)
            }
        })
    }
    if (name === 'item_subtotal') {
        enrollStore.enrollCart.map((v, k) => {
            if (v.product_id === item_code) {
                result = numeralFormat(v.member_price * v.qty)
            }
        })
    }
    return result
}

export function getEnrollProductSourceById(product_id) {
    let result = {}
    enrollStore.enrollProductSource.map((b, i) => {
        if (b.product_id == product_id) {
            result = b
        }
    })
    return result
}

/////////////////// Enroll CheckOut ///////////////////
export function saveEnrollToLocal() {
    let EnrollStorage = {
        enrollStore : enrollStore,
        checkOutEnrollStore: checkOutEnrollStore,
        shopStore : shopStore
    }
    localStorage.setItem('EnrollStorage', JSON.stringify(EnrollStorage));
}

export function restoreEnrollFromLocal() {
    if (localStorage.getItem('EnrollStorage') != null) {
        let ddd = localStorage.getItem('EnrollStorage')
        if(ddd !== "null") {
            let eee = JSON.parse(ddd)

            let ccc = eee.checkOutEnrollStore
            for (var property in ccc) {
                if (ccc.hasOwnProperty(property)) {
                    checkOutEnrollStore[property] = ccc[property]
                }
            }

            ccc = eee.enrollStore
            for (var property in ccc) {
                if (ccc.hasOwnProperty(property)) {
                    enrollStore[property] = ccc[property]
                }
            }

            ccc = eee.shopStore
            for (var property in ccc) {
                if (ccc.hasOwnProperty(property)) {
                    let prop = ['passCheckOut', 'enrollTotal', 'enrollTotalPv', 'enrollTotalLoading']
                    if (prop.indexOf(property) > -1) {
                        shopStore[property] = ccc[property]
                    }
                }
            }
        }

        deleteEnrollLocal()
    }
}

export function deleteEnrollLocal() {
    localStorage.removeItem('EnrollStorage');
}

/////////////////// Referral CheckOut /////////////////// 
export function resetReferralStore() {
    checkOutStore.shipping = '2'
    checkOutStore.referenceDetail = {}
    checkOutStore.referralValue = ''
    checkOutStore.referralPassword = ''
    checkOutStore.referralCheckedPassword = false
    checkOutStore.referralBy = 'by_referral_id'
    checkOutStore.referralAddress = {
        delivery: {},
        pickUp: {}
    }
    checkOutStore.referralAddressPH = {
        delivery: {},
        pickUpOrtigas: {},
        pickUpTaguig: {}
    }
    localStorage.setItem('checkOutStore', JSON.stringify(checkOutStore))
}

export function getRefferalDeliveryAddress(){
    const data = {
        fullName: storeEasyShop.referralAddress.delivery.firstNameEn + ' ' + storeEasyShop.referralAddress.delivery.lastNameEn,
        firstNameEn: storeEasyShop.referralAddress.delivery.firstNameEn,
        lastNameEn: storeEasyShop.referralAddress.delivery.lastNameEn,
        address1: storeEasyShop.referralAddress.delivery.address1,
        address2: storeEasyShop.referralAddress.delivery.address2,
        zip: storeEasyShop.referralAddress.delivery.zip,
        email: storeEasyShop.referralAddress.delivery.email,
        mobilePhone: storeEasyShop.referralAddress.delivery.mobilePhone,
        city: isCountryHongkong() ? appConfig.countryFullDisplay.singapore : storeEasyShop.referralAddress.delivery.city,
        country: getCountryCode(),
        state: "",
        comment: storeEasyShop.referralAddress.delivery.comment,
        referralID: storeEasyShop.referralAddress.delivery.referralID
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}

export function getRefferalPickUpAddress(){
    const data = {
        fullName: storeEasyShop.referralAddress.pickUp.firstNameEn + " " + storeEasyShop.referralAddress.pickUp.lastNameEn,
        firstNameEn: storeEasyShop.referralAddress.pickUp.firstNameEn,
        lastNameEn: storeEasyShop.referralAddress.pickUp.lastNameEn,
        address1: shippingAddress.pickUpAddress.address1,
        address2: shippingAddress.pickUpAddress.address2,
        zip: shippingAddress.pickUpAddress.zip,
        email: storeEasyShop.referralAddress.pickUp.email,
        mobilePhone: storeEasyShop.referralAddress.pickUp.mobilePhone,
        city: shippingAddress.pickUpAddress.city,
        country: getCountryCode(),
        state: "",
        referralID: storeEasyShop.referralAddress.pickUp.referralID
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}

export function getReferralAramexAddress(){
    let fullName = ''
    if (storeEasyShop.referralAddress.aramex.firstNameEn) {
        fullName = storeEasyShop.referralAddress.aramex.firstNameEn
    }
    if (storeEasyShop.referralAddress.aramex.lastNameEn) {
        fullName += " " + storeEasyShop.referralAddress.aramex.lastNameEn
    }
    const data = {
        fullName: fullName,
        firstNameEn: storeEasyShop.referralAddress.aramex.firstNameEn,
        lastNameEn: storeEasyShop.referralAddress.aramex.lastNameEn,
        address1: shippingAddress.aramexAddress.address1, // constant
        address2: shippingAddress.aramexAddress.address2, // constant
        zip: shippingAddress.aramexAddress.zip, // constant
        email: storeEasyShop.referralAddress.aramex.email,
        mobilePhone: '6565430300', // storeEasyShop.referralAddress.aramex.mobilePhone, // constant
        city: shippingAddress.aramexAddress.city,
        country: COUNTRY_CODE,
        state: '',
        sns1: storeEasyShop.referralAddress.aramex.sns1,
        sns2: storeEasyShop.referralAddress.aramex.sns2,
        referralID: storeEasyShop.referralAddress.delivery.referralID
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}

// Delivery & pickup address for enrol user
export function getReferralDeliveryAddressPH(){
    const data = {
        // checkOutStore.referralAddressPH lastNameEn
        fullName: checkOutStore.referralAddressPH.delivery.lastName + ", " + checkOutStore.referralAddressPH.delivery.firstName,
        firstName: checkOutStore.referralAddressPH.delivery.firstName,
        lastName: checkOutStore.referralAddressPH.delivery.lastName,
        address1: checkOutStore.referralAddressPH.delivery.address1,
        gender: checkOutStore.referralAddressPH.delivery.gender,
        birthDate: checkOutStore.referralAddressPH.delivery.birthDate,
        address2: checkOutStore.referralAddressPH.delivery.address2,
        city:  checkOutStore.referralAddressPH.delivery.city ,
        zip: checkOutStore.referralAddressPH.delivery.zipcode,
        country: COUNTRY_CODE,
        email: checkOutStore.referralAddressPH.delivery.email,
        mobilePhone: checkOutStore.referralAddressPH.delivery.mobilePhone,
        password1: checkOutStore.referralAddressPH.delivery.password,
        password2: checkOutStore.referralAddressPH.delivery.passwordconfirm,
        period: checkOutStore.commissionMonth,
        state: "",
        comment: "PCEnroll",
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}

export function getReferralTaguigPickUpAddress(){
    const data = {
        fullName: checkOutStore.referralAddressPH.pickUpTaguig.lastName + ", " + checkOutStore.referralAddressPH.pickUpTaguig.firstName,
        firstName: checkOutStore.referralAddressPH.pickUpTaguig.firstName,
        lastName: checkOutStore.referralAddressPH.pickUpTaguig.lastName,
        address1: shippingAddress.taguigPickUpAddress.address1,
        address2: shippingAddress.taguigPickUpAddress.address2,
        city: shippingAddress.taguigPickUpAddress.city,
        zip: shippingAddress.taguigPickUpAddress.zipcode,
        country: COUNTRY_CODE,
        email: checkOutStore.referralAddressPH.pickUpTaguig.email,
        mobilePhone: checkOutStore.referralAddressPH.pickUpTaguig.mobilePhone,
        password1: checkOutStore.referralAddressPH.delivery.password,
        password2: checkOutStore.referralAddressPH.delivery.passwordconfirm,
        period: checkOutStore.commissionMonth,
        comment: "PCEnroll-Pickup",
       
      
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}


// export function getReferralOrtigasPickUpAddress(){
//     const data = {
//         fullName: checkOutStore.referralAddressPH.pickUpOrtigas.firstNameEn + " " + checkOutStore.referralAddressPH.pickUpOrtigas.lastNameEn,
//         firstName: checkOutStore.referralAddressPH.pickUpOrtigas.firstName,
//         lastName: checkOutStore.referralAddressPH.pickUpOrtigas.lastNameEn,
//         address1: shippingAddress.ortigasPickUpAddress.address1,
//         address2: shippingAddress.ortigasPickUpAddress.address2,
//         zip: shippingAddress.ortigasPickUpAddress.zip,
//         email: checkOutStore.referralAddressPH.pickUpOrtigas.email,
//         mobilePhone: checkOutStore.referralAddressPH.pickUpOrtigas.mobilePhone,
//         city: shippingAddress.ortigasPickUpAddress.city,
//         country: COUNTRY_CODE,
//         state: "",
//     }
//     Object.keys(data).map((key) => {
//         const value = data[key]
//         if (isString(value)) {
//             data[key] = eliminateJSONEnemy(value)
//         }
//     })
//     return data
// }


export function getReferralBillingAddress(){
    switch (checkOutStore.shipping) {
        case shippingMethod.ID.delivery: return getRefferalDeliveryAddress()
        case shippingMethod.ID.pickUp: return getRefferalPickUpAddress()
        case shippingMethod.ID.aramex: return getReferralAramexAddress()
        case shippingMethod.ID.deliverySBY: return getRefferalDeliveryAddress()
        case shippingMethod.ID.pickUpSBY: return getRefferalPickUpAddress()
        default: throw 'Serious error, something wrong with getReferralBillingAddress()'
    }
}

export function getReferralBillingAddressPH(){
    if (checkOutStore.shipping === '2' || checkOutStore.shipping === 'delivery' ) {
        return getReferralDeliveryAddressPH()
    }
    // if (checkOutStore.shipping === '3') {
    //     return getReferralOrtigasPickUpAddress()
    // }
    if (checkOutStore.shipping === '4' || checkOutStore.shipping === 'pickUpTaguig') {
        return getReferralDeliveryAddressPH()
        // return getReferralDeliveryAddressPH()
        // return getReferralTaguigPickUpAddress()
    }
}

export function getReferralDeliverFormData(){
    const data = {
        language: 'English',
        fullName: getRefferalDeliveryAddress().fullName || "",
        firstName: getRefferalDeliveryAddress().firstNameEn || "",
        lastName: getRefferalDeliveryAddress().lastNameEn || "",
        password1: checkOutStore.referralPassword,
        password2: checkOutStore.referralPassword_confirm,
        address1: getRefferalDeliveryAddress().address1 || "",
        address2: (getRefferalDeliveryAddress().address2) || "",
        city: (COUNTRY_CODE === 'SG')? getDefaultCountry(COUNTRY_CODE): storeEasyShop.referralAddress.delivery.city,
        zip: getRefferalDeliveryAddress().zip || "",
        country: COUNTRY_CODE,
        email: getRefferalDeliveryAddress().email || "",
        mobilePhone: getRefferalDeliveryAddress().mobilePhone || "",
        period: checkOutStore.commissionMonth,
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}

export function getReferralPickupFormData(){
    const data = {
        language: 'English',
        fullName: getRefferalPickUpAddress().fullName || "",
        firstName: getRefferalPickUpAddress().firstNameEn || "",
        lastName: getRefferalPickUpAddress().lastNameEn || "",
        password1: checkOutStore.referralPassword,
        password2: checkOutStore.referralPassword_confirm,
        address1: shippingAddress.pickUpAddress.address1,
        address2: shippingAddress.pickUpAddress.address2,
        city: shippingAddress.pickUpAddress.city,
        zip: shippingAddress.pickUpAddress.zip,
        country: COUNTRY_CODE,
        email: getRefferalPickUpAddress().email || "",
        mobilePhone: getRefferalPickUpAddress().mobilePhone || "",
        period: checkOutStore.commissionMonth,
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}

export function getReferralAramexFormData(){
    const sns = `${defaults(storeEasyShop.referralAddress.aramex.sns1, '')}${defaults(storeEasyShop.referralAddress.aramex.sns2,'')}`
    const data = {
        language: 'English',
        fullName: `${defaults(storeEasyShop.referralAddress.aramex.firstNameEn, '')} ${defaults(storeEasyShop.referralAddress.aramex.lastNameEn, '')}`,
        firstName: defaults(storeEasyShop.referralAddress.aramex.firstNameEn, ''),
        lastName: defaults(storeEasyShop.referralAddress.aramex.lastNameEn, ''),
        address1: defaults(getReferralAramexAddress().address1, ''),
        address2: defaults(getReferralAramexAddress().address2, ''),
        city: defaults(getReferralAramexAddress().city, ''),
        zip: defaults(getReferralAramexAddress().zip, ''),
        country: defaults(getReferralAramexAddress().country, ''),
        email: defaults(storeEasyShop.referralAddress.aramex.email, ''),
        mobilePhone: defaults(getReferralAramexAddress().mobilePhone, ''),
        sns: sns,
        comment: '',
        period: checkOutStore.commissionMonth
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}

export function getReferralPickupFormDataTHA(){
    const data = {
        language: 'English',
        fullName: getRefferalPickUpAddress().fullName || "",
        firstName: getRefferalPickUpAddress().firstNameEn || "",
        lastName: getRefferalPickUpAddress().lastNameEn || "",
        password1: checkOutStore.referralPassword,
        password2: checkOutStore.referralPassword_confirm,
        address1: shippingAddress.pickUpAddressTHA[store.language.language].address1,
        address2: shippingAddress.pickUpAddressTHA[store.language.language].address2,
        city: shippingAddress.pickUpAddressTHA[store.language.language].city,
        zip: shippingAddress.pickUpAddressTHA[store.language.language].zip,
        country: COUNTRY_CODE,
        email: getRefferalPickUpAddress().email || "",
        mobilePhone: getRefferalPickUpAddress().mobilePhone || "",
        period: checkOutStore.commissionMonth,
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}

export function getReferralFormData(){
    if (checkOutStore.shipping === '2') {
        return getReferralDeliverFormData()
    }
    if (checkOutStore.shipping === '3') {
        return getReferralPickupFormData()
    }
    if (checkOutStore.shipping === '4') {
        return getReferralAramexFormData()
    }
}
export function getReferralFormDataTHA(){
    if (checkOutStore.shipping === '2') {
        return getReferralDeliverFormData()
    }
    if (checkOutStore.shipping === '3') {
        return getReferralPickupFormDataTHA()
    }
}
//Referral form data for enrol users
export function getReferralDeliverFormDataPH(){
    const landmark =  getReferralDeliveryAddressPH().comment ?  " (" + getReferralDeliveryAddressPH().comment + ")"  :   getReferralDeliveryAddressPH().comment || '' ;
 
   

    const data = {
        language: 'English',
        fullName: getReferralDeliveryAddressPH().fullName || "",
        firstName: getReferralDeliveryAddressPH().firstName || "",
        lastName: getReferralDeliveryAddressPH().lastName || "",
        password1: getReferralDeliveryAddressPH().password1,
        password2: getReferralDeliveryAddressPH().password2,
        address1: getReferralDeliveryAddressPH().address1 || "",
        address2: (getReferralDeliveryAddressPH().address2) || "",
        gender: (getReferralDeliveryAddressPH().gender) || "",
        birthDate: getReferralDeliveryAddressPH().birthDate,
        city: getReferralDeliveryAddressPH().city + " City" ,
        zip: getReferralDeliveryAddressPH().zip || "",
        country: getReferralDeliveryAddressPH().country,
        email: getReferralDeliveryAddressPH().email || "",
        mobilePhone: getReferralDeliveryAddressPH().mobilePhone || "",
        period: checkOutStore.commissionMonth,
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}


export function getReferralTaguigPickupFormData(){
    const data = {
        language: 'English',
        fullName: getReferralTaguigPickUpAddress().fullName || "",
        firstName: getReferralTaguigPickUpAddress().firstName || "",
        lastName: getReferralTaguigPickUpAddress().lastName || "",
        password1: getReferralTaguigPickUpAddress().password1,
        password2: getReferralTaguigPickUpAddress().password2,
        address1: getReferralTaguigPickUpAddress().address1,
        address2: getReferralTaguigPickUpAddress().address2,
        city: getReferralTaguigPickUpAddress().city,
        zip: getReferralTaguigPickUpAddress().zip,
        country: getReferralTaguigPickUpAddress().country,
        email: getReferralTaguigPickUpAddress().email || "",
        mobilePhone: getReferralTaguigPickUpAddress().mobilePhone || "",
        period: checkOutStore.commissionMonth,
    }
    Object.keys(data).map((key) => {
        const value = data[key]
        if (isString(value)) {
            data[key] = eliminateJSONEnemy(value)
        }
    })
    return data
}

// export function getReferralOrtigasPickupFormData(){
//     const data = {
//         language: 'English',
//         fullName: getReferralOrtigasPickUpAddress().fullName || "",
//         firstName: getReferralOrtigasPickUpAddress().firstNameEn || "",
//         lastName: getReferralOrtigasPickUpAddress().lastNameEn || "",
//         password1: checkOutStore.referralPassword,
//         password2: checkOutStore.referralPassword_confirm,
//         address1: shippingAddress.ortigasPickUpAddress.address1,
//         address2: shippingAddress.ortigasPickUpAddress.address2,
//         city: shippingAddress.ortigasPickUpAddress.city,
//         zip: shippingAddress.ortigasPickUpAddress.zip,
//         country: COUNTRY_CODE,
//         email: getReferralOrtigasPickUpAddress().email || "",
//         mobilePhone: getReferralOrtigasPickUpAddress().mobilePhone || "",
//         period: checkOutStore.commissionMonth,
//     }
//     Object.keys(data).map((key) => {
//         const value = data[key]
//         if (isString(value)) {
//             data[key] = eliminateJSONEnemy(value)
//         }
//     })
//     return data
// }


export function getReferralFormDataPH(){
    // console.log("referralAddressPHValidator",referralAddressPHValidator);
    // console.log("checkOutStore.shipping",checkOutStore.shipping)
    
    if (checkOutStore.shipping === '2' || checkOutStore.shipping === 'delivery' ) {
        return getReferralDeliverFormDataPH()
    }
   
    if (checkOutStore.shipping === '4' || checkOutStore.shipping === 'pickUpTaguig') {
        return getReferralDeliverFormDataPH()
        // return getReferralTaguigPickupFormData()
    }
}

/////////////////// Shopping CheckOut ///////////////////
export function saveCheckOutToLocal() {
    let ShoppingStorage = {
        checkOutStore: checkOutStore,
        shopStore : shopStore
    }
    localStorage.setItem('ShoppingStorage', JSON.stringify(ShoppingStorage));
}

export function resetCheckOutEnrollStore() {
    Object.keys(checkOutEnrollStore).map((a) => {
        checkOutEnrollStore[a] = defaultCheckOutEnrollStore[a]
    })
}

export function resetCheckOutStore() {
    Object.keys(checkOutStore).map((a) => {
        checkOutStore[a] = defaultCheckOutStore[a]
    })
}

export function resetShopStore() {
    Object.keys(shopStore).map((a) => {
        shopStore[a] = defaultShopStore[a]
    })

    shopStore.productDataSource.map((v,k) => {
        v.qty = 0
    })
}

export function resetEnrollStore() {
    Object.keys(enrollStore).map((a) => {
        enrollStore[a] = defaultEnrollStore[a];
    })
}

export function restoreCheckOutFromLocal() {
    if (localStorage.getItem('ShoppingStorage') != null) {
        let ddd = localStorage.getItem('ShoppingStorage')
        if(ddd !== "null") {
            let eee = JSON.parse(ddd)

            let ccc = eee.checkOutStore
            for (var property in ccc) {
                if (ccc.hasOwnProperty(property)) {
                    checkOutStore[property] = ccc[property]
                }
            }

            ccc = eee.shopStore
            for (var property in ccc) {
                if (ccc.hasOwnProperty(property)) {
                    shopStore[property] = ccc[property]
                }
            }
        }
        shopStore.passCheckOut = false
        deleteCheckOutLocal()
    }
}

export function deleteCheckOutLocal() {
    // localStorage.setItem('ShoppingStorage', null);
}

///////////// JP Enroll AutoShip /////////////

export function getAllCartForCheckStock(supportedMarkets, productEnrollCart, autoshipEnrollCart) {
    let starterKit = ''
    let starterKitProduct = []
    let firstProducts = []
    let autoshipProducts = []
    supportedMarkets.forEach((b) => {
        if(b.code === getCountryCode()){
            starterKit = b.starterKit.product_id
        }
    })
    productEnrollCart.map((v)=> {
        if(v.qty > 0) {
            if(v.product_id === starterKit){
                starterKitProduct.push({
                    quantity: v.qty,
                    item_code: v.product_id
                })
            } else {
                firstProducts.push({
                    quantity: v.qty,
                    item_code: v.product_id
                })
            } 
        }     
    })
    autoshipEnrollCart.map((v, k) => {
        if (v.qty > 0) {
            autoshipProducts.push({
                quantity: v.qty,
                item_code: v.product_id
            })
        }
    })

    let allCartItems = []
    allCartItems = allCartItems.concat(starterKitProduct, firstProducts, autoshipProducts) 

    return {
        allCartItems : allCartItems,
        starterKitProduct: starterKitProduct,
        firstProducts: firstProducts,
        autoshipProducts: autoshipProducts
    }
}

export function isFreeEnroll() {
    if (enrollStore.enrollData.isFreeEnroll == "1") {
        return true
    } else {
        return false
    }
}

export function getEnrollAutoShipCart() {
    return enrollStore.enrollData.enrollAutoShipCart
}

export function isEnrollAutoShipCart() {
    let autoShipCart = getEnrollAutoShipCart()
    if (typeof autoShipCart !== 'undefined' && autoShipCart != null && autoShipCart.length > 0) {
        return true
    } else {
        return false
    }
}

export function isLocalEnrollAutoShipCart() {
    let autoShipCart = getLocalEnrollAutoShipCart()
    if (typeof autoShipCart !== 'undefined' && autoShipCart != null && autoShipCart.length > 0) {
        return true
    } else {
        return false
    }
}

export function getLocalEnrollAutoShipCart() {
    let autoShipCart = []
    try {
        autoShipCart = JSON.parse(localStorage.getItem('storageAutoshipEnrollCart'))
    } catch (e) {}
    return autoShipCart
}

export function deleteEnrollData() {
    localStorage.removeItem('storageAutoshipEnrollCart')
    localStorage.removeItem('storageEnrollCart')
    resetCheckOutEnrollStore()
    resetCheckOutStore()
    resetShopStore()
    resetEnrollStore()
    deleteEnrollLocal()
}

export function removeFreeEnrollData() {
    enrollStore.enrollData.isFreeEnroll = ''
    enrollStore.enrollData.enrollAutoShipCart = []
    enrollStore.enrollData.isCopyCreditCard = ''
    enrollStore.enrollData.enrollNewId = ''
    enrollStore.enrollData.enrollOrderId = ''
    enrollStore.enrollData.enrollAutoShipStep = ''
}

export function restoreFreeEnrollData(db_result, username, order_id, autoShipStep) {
    enrollStore.enrollData.isFreeEnroll = db_result.isFreeEnroll
    enrollStore.enrollData.enrollAutoShipCart = db_result.enrollAutoShipCart
    enrollStore.enrollData.isCopyCreditCard = db_result.isCopyCreditCard
    enrollStore.enrollData.enrollNewId = username
    enrollStore.enrollData.enrollOrderId = order_id
    enrollStore.enrollData.enrollAutoShipStep = autoShipStep
}

export function getFreeEnrollData(order_id, callback) {
    let data = {
        order_id: order_id
    }

    window.$.post(apiURL.JP.getautoshipcart, JSON.stringify(data))
        .done(function (result) {
            if (callback) {
                callback(result)
            }
        })
        .fail(function (error) {
            console.log(error)
        });
}

export function changeBAToStatusD(baId, userToken, callback) {
    window.$.ajax({
        'type':'get',
        'url':'https://hydra.unicity.net/v5a/customers?unicity=' + baId,
        'success':function (result2) {
            // var href = result2.items[0].href.replace("https://hydra.unicity.net/","https://hydraqa.unicity.net/");
            var href = result2.items[0].href
            // var token = 'csEnrollmentSpoke03Spoke97:7rtZSiVtmoeU5hULldhQ' // userToken
            var data = {
                "status": "Defective",
                "type": "Associate"
            }
            var data = JSON.stringify(data);
            window.$.ajax({
                'type':'PATCH',
                // 'headers':{'Content-Type':'application/json','Authorization':'Bearer ' + token},
                'headers':{'Content-Type':'application/json'},
                'url': href,
                'data': data,
                'success':function (result3) {
                    callback('success', result3)
                },
                'error':function (result3) {
                    callback('error', result3)
                }
            });     
            callback('success', result2)
        },
        'error':function (result2) {
            callback('error', result2)
        }
    });
}