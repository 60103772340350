import $ from 'jquery'
import dataCountry from '@Configs/json/country.json'
import { devTools, store, staticFooter } from '@Stores/MainStore'
import {
    isProduction,
    getParameterByName,
    dictionary,
    isWordExist,
    getStoreLanguage,
    dictionaryNative
} from '@GlobalHelpers'
import {
    isNullOrUndefined,
    isUndefined,
    isNothing,
    isString
} from '@Utils/Utils'
import { paymentGateway } from '@Utils/PaymentGateway'
import { getCustomerType, isAnonymous } from '@Customer'
import { hasQueryString } from '@Configs/ConfigsHeader'
import { Country } from '@Configs/Country'
import { language } from '@Language'
import { storeBalance } from '@Stores/StoreBalance'
import get from 'lodash/get'

// ----------------------------------
let pathname = window.location.pathname.split('/')
let location = 'korea'
// ----------------------------------

// -- Debugging Parameters Control --
try {
    const _getParameterByName = (name, url) => {
        if (!url) url = window.location.href
        name = name.replace(/[\[\]]/g, '\\$&')
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return ''
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
    }

    // Force clear localStorage
    if (_getParameterByName('clear') === 'true') {
        localStorage.clear()
        sessionStorage.clear()
        alert('Clear completed, Please removed parameter.')
    }
    // Live Production Simulation - used on Development stage
    if (_getParameterByName('live') === 'true') {
        devTools.isSimulateLive = true
    }
    // Enable Information Logs
    if (_getParameterByName('info') === 'true') {
        devTools.isShowInfoLog = true
    }
    // Enable Profiler Logs
    if (_getParameterByName('pro') === 'true') {
        devTools.isShowProfilerLog = true
    }

    if (hasQueryString('error')) {
        devTools.isHasError = true
        console.log('[DevTools] Simulate errors actived.')
    }

    if (hasQueryString('dev')) {
        devTools.useCustomizer = true
        console.log('[DevTools] Customizer actived.')
    }
} catch (e) {
    console.error(e)
}

/** Development version which always be change regarded to specific local time, please `feel free` to change it.
 * This variable is useful for checking the development stage if it has been done its build.
 * Clear all `localStorage` and `sessionStorage` when different version have been detected. */
export const VERSION_DEV = '201106.2.0.0' // <= Change new version here!

export const ENV =
    window.location.hostname === 'localhost' ? 'dev' : 'production'

export const appConfig = {
    appPath: '/' + location,
    assetPath: '/assets/',
    numeralFormat: numeral('int', location.toLowerCase()),
    numeralDecimalFormat: numeral('decimal', location.toLowerCase()),
    preferredPrice: false,
    country: location,
    GACode: {
        singapore: 'G-8ZV61MEKWE',
        thailand: 'G-C3624NNSS6',
        japan: 'G-N2X7G74LK8',
        india: '',
        philippines: 'G-6RFWDY7V23',
        united_states: '',
        bahrain: '',
        vietnam: '',
        indonesia: 'G-TSC0CH5P2R',
        malaysia: 'G-4VQB90KRNX',
        hongkong: 'G-FCTPMQNXJJ',
        australia: '',
        newzealand: '',
        taiwan: 'G-QWH2FJ2WJ5'
    },
    FBPixelCode: {
        singapore: '388467111504630',
        thailand: '301116716951336',
        japan: '388467111504630',
        india: '388467111504630',
        philippines: '388467111504630',
        united_states: '388467111504630',
        bahrain: '388467111504630',
        vietnam: '388467111504630',
        indonesia: '388467111504630',
        malaysia: '',
        australia: '',
        hongkong: '',
        newzealand: '',
        taiwan: ''
    },
    GAAdwordsCode: {
        singapore: 'AW-869476933',
        thailand: 'AW-869476933',
        japan: 'AW-869476933',
        india: 'AW-869476933',
        philippines: 'AW-869476933',
        united_states: 'AW-869476933',
        bahrain: 'AW-869476933',
        vietnam: 'AW-869476933',
        indonesia: 'AW-869476933',
        malaysia: '',
        australia: '',
        newzealand: '',
        taiwan: ''
    },
    language: {
        singapore: ['EN'],
        thailand: ['TH', 'EN'],
        japan: ['JP', 'EN'],
        bahrain: ['EN'],
        philippines: ['EN'],
        vietnam: ['VN', 'EN'],
        indonesia: ['ID', 'EN'],
        uae: ['EN', 'AR'],
        taiwan: ['TW', 'EN'],
        hongkong: ['HK', 'EN'],
        cambodia: ['KH', 'EN'],
        malaysia: ['EN'],
        laos: ['EN', 'LA'],
        myanmar: ['EN'],
        australia: ['EN'],
        newzealand: ['EN'],
        korea: ['EN']
    },
    languageName: {
        SG: ['English'],
        TH: ['ภาษาไทย'],
        JP: ['日本語'],
        EN: ['English'],
        PH: ['Philippines'],
        MY: ['English'],
        VN: ['Tiếng Việt'],
        CN: ['简体中文'],
        TW: ['繁體中文'],
        AR: ['Arabic'],
        ID: ['Bahasa'],
        HK: ['繁體'],
        KH: ['Khmer'],
        LA: ['lao'],
        AU: ['English'],
        NZ: ['English'],
        KR: ['한국어']
    },
    plan: {
        global: ['US', 'IN', 'AE'],
        asian: [
            'TH',
            'SG',
            'BH',
            'VN',
            'ID',
            'TW',
            'HK',
            'KH',
            'PH',
            'LA',
            'AU',
            'NZ'
        ],
        japan: ['JP'],
        korea: ['KR']
    },
    flags: {
        SG: 'singapore.svg',
        TH: 'thailand.svg',
        EN: 'united-states.png',
        PH: 'philippines.svg',
        JP: 'japan.svg',
        MY: 'malaysia.svg',
        VN: 'vietnam.svg',
        CN: 'hongkong.png',
        AE: 'dubai.png',
        ID: 'indonesia.svg',
        KH: 'cambodia.svg',
        LA: 'laos.svg',
        AU: 'australia.svg',
        NZ: 'newzealand.svg',
        HK: 'hongkong.png',
        KR: 'korea.png',
        TW: 'taiwan.svg'
    },
    shippingMethod: {
        TH: ['delivery', 'pickup'],
        SG: ['delivery', 'pickup', 'aramex'],
        SG_aramex: ['pickup', 'aramex'],
        SG_referral: ['delivery', 'pickup'],
        VN: ['delivery', 'pickup_hanoi', 'pickup_hochimin'],
        ID: ['delivery', 'pickup', 'deliverySBY', 'pickUpSBY', 'motordelivery'],
        HK: ['delivery', 'pickup'],
        AU: ['delivery'],
        NZ: ['delivery'],
        TW: ['delivery', 'pickup']
    },
    tax: {
        thailand: 1.07,
        singapore: 1,
        japan: 1,
        malaysia: 1,
        philippines: 1,
        vietnam: 1,
        indonesia: 1,
        uae: 1,
        taiwan: 1,
        hongkong: 1,
        australia: 1,
        newzealand: 1
    },
    warehouse: {
        JP: ['1-JPN MAIN'],
        SG: ['3-Online'],
        TH: ['906-Online'],
        PH: ['1-Philippines Warehouse'],
        HK: ['1-Hong Kong'],
        //VN: ['1-HN-WH', '3-HCM WH'],
        VN: ['1-Hanoi'],
        ID: ['1-Online'],
        AE: ['3-Online'],
        TW: ['2–TWN Sales'], //2 – TWN Sales
        // HK: ['3-Online'], //1-Hong Kong
        KH: ['99-Temp'],
        MY: ['1-Online'],
        LA: ['1-Laos'],
        MM: ['1- mmr'],
        AU: ['1-Online'],
        NZ: ['1-Online'],
        KR: ['1-Online']
    },
    currency: {
        TH: ['THB'],
        JP: ['JPY'],
        SG: ['SGD'],
        PH: ['PHP'],
        VN: ['VND'],
        ID: ['IDR'],
        AE: ['AED'],
        TW: ['TWD'],
        HK: ['HKD'],
        KH: ['USD'],
        MY: ['MYR'],
        LA: ['TAK'],
        MM: ['Kyat'],
        AU: ['AUS'],
        NZ: ['NZL']
    },
    pvRange: {
        JP: [0, 1000],
        TH: [0, 1000],
        SG: [0, 1000],
        PH: [0, 1000],
        VN: [0, 1000],
        ID: [0, 1000],
        AE: [0, 1000],
        TW: [0, 1000],
        KH: [0, 1000],
        MY: [0, 1000],
        HK: [0, 1000],
        LA: [0, 1000],
        MM: [0, 1000],
        AU: [0, 1000],
        NZ: [0, 1000]
    },
    priceRange: {
        JP: [0, 1000000],
        TH: [0, 1000000],
        SG: [0, 1000000],
        PH: [0, 1000000],
        VN: [0, 20000000],
        ID: [0, 20000000],
        AE: [0, 1000000],
        TW: [0, 1000000],
        HK: [0, 1000000],
        KH: [0, 1000000],
        MY: [0, 1000000],
        LA: [0, 20000000],
        MM: [0, 1000000],
        AU: [0, 1000000],
        NZ: [0, 1000000]
    },
    inputRange: {
        JP: [0, 1000],
        TH: [0, 1000],
        SG: [0, 1000],
        PH: [0, 1000],
        VN: [0, 1000],
        ID: [0, 1000],
        AE: [0, 1000],
        TW: [0, 1000],
        HK: [0, 1000],
        KH: [0, 1000],
        MY: [0, 1000],
        LA: [0, 1000],
        MM: [0, 1000],
        AU: [0, 1000],
        NZ: [0, 1000]
    },
    fbCustomerChatPageId: {
        thailand: '172740789986067',
        singapore: '399302273752593',
        japan: '1448043158798194',
        philippines: '',
        vietnam: '',
        indonesia: '',
        uae: '',
        hongkong: '',
        taiwan: '',
        hongkong: '',
        cambodia: '',
        malaysia: '',
        laos: '',
        myanmar: '',
        australia: '',
        newzealand: ''
    },
    countryOnDev: {
        singapore: true,
        thailand: true,
        japan: true,
        india: true,
        philippines: true,
        united_states: true,
        bahrain: true,
        vietnam: true,
        indonesia: true,
        uae: true,
        taiwan: true,
        hongkong: true,
        cambodia: true,
        malaysia: true,
        laos: true,
        myanmar: true,
        australia: true,
        newzealand: true,
        korea: true
    },
    countryOnProduction: {
        singapore: true,
        thailand: true,
        japan: true,
        india: false,
        philippines: true,
        united_states: false,
        bahrain: false,
        vietnam: true,
        indonesia: true,
        taiwan: true,
        hongkong: true,
        cambodia: false,
        malaysia: true,
        laos: true,
        myanmar: true,
        australia: true,
        newzealand: true,
        korea: false
    },
    defaultCountryOnDev: 'singapore',
    defaultCountryOnProduction: 'singapore',
    // -----------------------
    // -- Country Constant --
    countryCode: {
        singapore: 'SG',
        thailand: 'TH',
        japan: 'JP',
        india: 'IN',
        philippines: 'PH',
        united_states: 'US',
        bahrain: 'BH',
        vietnam: 'VN',
        indonesia: 'ID',
        uae: 'AE',
        taiwan: 'TW',
        hongkong: 'HK',
        cambodia: 'KH',
        malaysia: 'MY',
        laos: 'LA',
        myanmar: 'MM',
        australia: 'AU',
        newzealand: 'NZ',
        korea: 'KR'
    },
    countryCode3: {
        singapore: 'SGP',
        thailand: 'THA',
        japan: 'JPN',
        india: 'IN',
        philippines: 'PHL',
        united_states: 'USA',
        bahrain: 'BHA',
        vietnam: 'VNM',
        indonesia: 'IDN',
        uae: 'ARE',
        taiwan: 'TWN',
        hongkong: 'HKG',
        cambodia: 'KHM',
        malaysia: 'MYS',
        laos: 'LAO',
        myanmar: 'MMR',
        australia: 'AUS',
        newzealand: 'NZL',
        korea: 'KOR'
    },
    countryFullDisplay: {
        singapore: 'Singapore',
        thailand: 'Thailand',
        japan: 'Japan',
        india: 'India',
        philippines: 'Philippines',
        united_states: 'United States',
        bahrain: 'Bahrain',
        vietnam: 'Vietnam',
        indonesia: 'Indonesia',
        uae: 'United Arab Emirates',
        taiwan: 'Taiwan',
        hongkong: 'Hongkong',
        cambodia: 'Cambodia',
        malaysia: 'Malaysia',
        laos: 'Laos',
        myanmar: 'Myanmar',
        australia: 'Australia',
        newzealand: 'New Zealand',
        korea: 'Korea'
    },
    countryFull: {
        singapore: 'singapore',
        thailand: 'thailand',
        japan: 'japan',
        india: 'india',
        philippines: 'philippines',
        united_states: 'united_states',
        bahrain: 'bahrain',
        hongkong: 'hongkong',
        vietnam: 'vietnam',
        indonesia: 'indonesia',
        uae: 'uae',
        taiwan: 'taiwan',
        hongkong: 'hongkong',
        cambodia: 'cambodia',
        malaysia: 'malaysia',
        laos: 'laos',
        myanmar: 'myanmar',
        australia: 'australia',
        newzealand: 'newzealand',
        korea: 'korea'
    },
    countryCodeToFullDisplay: {
        SG: 'Singapore',
        TH: 'Thailand',
        JP: 'Japan',
        ID: 'India',
        PH: 'Philippines',
        US: 'United States',
        BH: 'Bahrain',
        VN: 'Vietnam',
        HK: 'Hongkong',
        ID: 'Indonesia',
        AE: 'United Arab Emirates',
        TW: 'Taiwan',
        KH: 'Cambodia',
        MY: 'Malaysia',
        LA: 'Laos',
        MM: 'Myanmar',
        AU: 'Australia',
        NZ: 'New Zealand'
    },
    countryCodeToFull: {
        SG: 'singapore',
        TH: 'thailand',
        JP: 'japan',
        ID: 'india',
        PH: 'philippines',
        US: 'united_states',
        BH: 'bahrain',
        VN: 'vietnam',
        ID: 'indonesia',
        AE: 'uae',
        TW: 'taiwan',
        HK: 'hongkong',
        KH: 'cambodia',
        MY: 'malaysia',
        LA: 'laos',
        MM: 'myanmar',
        AU: 'australia',
        NZ: 'newzealand'
    },
    // -----------------------
    // -- Language Constant --
    languageCode: {
        english: 'EN',
        thai: 'TH',
        japanese: 'JP',
        philippines: 'EN',
        hongkong: 'HK',
        vietnamese: 'VN',
        indonesia: 'ID',
        vietnamese: 'VN',
        bahasa: 'ID',
        arab: 'AR',
        khmer: 'KH',
        lao: 'LA',
        burmese: 'MM',
        korean: 'KR'
    },
    // ----------------------------------
    // -- Shipping & Customer Constant --
    shippingMethodType: {
        expedited: 'Expedited',
        economy: 'Economy'
    },
    shippingMethodID: {
        delivery: '2',
        pickup: '3',
        aramex: '4',
        motorDelivery: '6',
        deliverySBY: '7',
        pickUpSBY: '8'
    },
    shippingMethodIDToName: {
        '2': 'delivery',
        '3': 'pickUp',
        '4': 'aramex',
        '6': 'motorDelivery',
        '7': 'deliverySBY',
        '8': 'pickUpSBY'
    },
    // -----------------------
    hostname: {
        localhost: 'localhost',
        development: 'dev',
        production: 'production',
        test: 'test'
    },
    footerLanguageControl: {
        english: 'english',
        native: 'native'
    },
    cardIssuer: {
        american_express: 'AMEX',
        mastercard: 'Mastercard',
        discover: 'Discover',
        diners: 'Diners',
        diners_carteblanche: 'Diners - Carte Blanche',
        jcb: 'JCB',
        visa: 'Visa',
        visa_electron: 'Visa Electron'
    },
    productRemarks: {
        noautoship: 'no_autoship'
    },
    rankShort: {
        MBR: 'Mgr',
        // regular ranks
        MGR: 'Mgr',
        SRM: 'SrM',
        EXM: 'ExM',
        DIR: 'Dir',
        SRD: 'SrD',
        EXD: 'ExD',
        PRD: 'PrD',
        PRS: 'PrS',
        PRR: 'PrR',
        DIA: 'DIA',
        CCM: 'CCM',
        // special case ranks
        DST: 'Fo',
        PH1: 'Ph1',
        AFP: 'AFP',
        DIR2: 'DIA2'
    },
    hashKey: 'WWW-UNICITY-KEY'
}

export function convertCountryString(stringToConvert, convertFrom, convertTo) {
    try {
        let data = {}
        if (dataCountry) {
            data = dataCountry.find(
                item =>
                    item[convertFrom].toLowerCase() ===
                    stringToConvert.toLowerCase()
            )
        }

        if (Object.keys(data).length > 0) {
            return data[convertTo]
        } else {
            return stringToConvert
        }
    } catch (e) {
        return stringToConvert
    }
}
export function numeral(type, country) {
    const format = {
        singapore: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0.00'
        },
        thailand: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0.00'
        },
        japan: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0'
        },
        philippines: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0.00'
        },
        vietnam: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0'
        },
        indonesia: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0'
        },
        uae: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0'
        },
        taiwan: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0.00'
        },
        hongkong: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0.00'
        },
        cambodia: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0'
        },
        malaysia: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0.00'
        },
        laos: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0'
        },
        myanmar: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0'
        },
        australia: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0.00'
        },
        newzealand: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0'
        },
        korea: {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0'
        }
    }

    // if (format[country] === undefined) {
    //     let data = {
    //         "source": 'UNISHOP-WEB',
    //         "country_code": ''
    //     };
    //     $.ajax({
    //         'type': 'GET',
    //         'async': false,
    //         'url': 'https://api.ipstack.com/check?access_key=bddc97ea08f502db759bcc7524f76949',
    //         'success': function (result) {
    //             data = {
    //                 "source": 'UNISHOP-WEB',
    //                 "country_code": result.country_code
    //             };
    //             data = JSON.stringify(data);
    //             $.ajax({
    //                 'type': 'POST',
    //                 'async': false,
    //                 'url': 'https://member-calls4.unicity.com/ALL/ALL_Unishop_Geolocation.asp',
    //                 'data': { strData: data },
    //                 'success': function (res) {
    //                     res = JSON.parse(res)
    //                     let u = res.url.split('/')[3]
    //                     let current_split_url = window.location.href.split('/')[3];
    //                     let current_split_url_2 = window.location.href.split('/')[4];

    //                     const checkUrl = async (current_split_url, current_split_url_2) => {

    //                         let notFound = true
    //                         await $.each(format, (country) => {

    //                             let country_re = new RegExp(`${country}*`)

    //                             let converted2 = convertCountryString(current_split_url, 'alpha-2', 'name')
    //                             let converted3 = convertCountryString(current_split_url, 'alpha-3', 'name')
    //                             let check_alpha2 = country_re.test(converted2.toLowerCase());
    //                             let check_alpha3 = country_re.test(converted3.toLowerCase());

    //                             if (check_alpha2 || check_alpha3) {
    //                                 window.location.href = `/${country}${current_split_url_2 ? `/${current_split_url_2}` : ''}`
    //                                 notFound = false
    //                             }

    //                         });

    //                         if(notFound) {
    //                             window.location.href = `/singapore/page-not-found`
    //                         }
    //                     }

    //                     if (!current_split_url) {
    //                         window.location.href = '/' + u
    //                     } else {
    //                         checkUrl(current_split_url, current_split_url_2)
    //                     }

    //                 },
    //                 'error': function (err) {
    //                     window.location.href = '/singapore'
    //                 }
    //             });
    //         },
    //         'error': function (result) {
    //             window.location.href = '/singapore'
    //         }
    //     });
    // }

    // if (format[country] === undefined) {
    //     // if geolocation is working, stop the loop by throw an exception
    //     throw ': Redirect to nearest country.'
    // } else {
    //     // TODO: Check below code, why it always be singapore (ref to old code)
    //     country = 'singapore'
    // }

    if (country && format[country]) {
        if (type === 'int') {
            return format[country].numeralFormat
        } else if (type === 'decimal') {
            return format[country].numeralDecimalFormat
        }
    }
}

export const supportedMarkets = [
    {
        name: 'thailand',
        title: 'UNICITY (TH)',
        code: 'TH',
        currency: 'THB',
        starterKit: {
            product_id: '20817',
            post_title: 'Starter Kit',
            post_title_2: 'ชุดเริ่มต้นธุรกิจ',
            pv: 0,
            member_price: '500',
            image:
                'https://member-calls4.unicity.com/media/unishop/sgp/sku/27051.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|enroll|'
        }
    },
    {
        name: 'singapore',
        title: 'UNICITY (SG)',
        code: 'SG',
        currency: 'SGD',
        starterKit: {
            product_id: '32534',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 43,
            image:
                'https://member-calls4.unicity.com/media/unishop/sgp/sku/32534.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|enroll|'
        }
    },
    {
        name: 'japan',
        title: 'UNICITY (JP)',
        code: 'JP',
        currency: 'JPY',
        starterKit: {
            product_id: '21514',
            post_title: 'Member Kit',
            post_title2: 'ﾒﾝﾊﾞｰ ｷｯﾄ',
            pv: 0,
            image:
                'https://member-calls4.unicity.com/media/unishop/sgp/sku/27051.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|enroll|',
            price: '3300'
        }
    },
    {
        name: 'philippines',
        title: 'UNICITY (PH)',
        code: 'PH',
        currency: 'PHP',
        pcKit: {
            product_id: '31325',
            post_title: 'Preferred Customer Fee',
            pv: 0,
            member_price: 200,
            image:
                'https://member-kr2.unicity.com/unifoapi/assets/false/PHL/unicitylogo-f8a6a17e.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|PCenroll|'
        },
        birFee: {
            product_id: '31893',
            post_title: 'Business Registration Fee',
            pv: 0,
            member_price: 1500,
            image:
                'https://member-kr2.unicity.com/unifoapi/assets/false/PHL/unicitylogo-f8a6a17e.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|fee|'
        },

        starterKit: [
            {
                product_id: '31260',
                post_title: 'Starter Kit',
                pv: 500,
                member_price: 45060,
                image:
                    'https://member-calls4.unicity.com/media/unishop/phl/sku/31260.jpg',
                qty: 1,
                stock: '99',
                status: '1',
                allow_backorder: '1',
                remarks: '|enroll|'
            },
            {
                product_id: '29603',
                post_title: 'Starter Kit',
                pv: 0,
                member_price: 600,
                image:
                    'https://member-calls4.unicity.com/media/unishop/phl/sku/29603.jpg',
                qty: 1,
                stock: '99',
                status: '1',
                allow_backorder: '1',
                remarks: '|enroll|'
            },
            {
                product_id: '31261',
                post_title: 'Starter Kit',
                pv: 536,
                member_price: 46760,
                image:
                    'https://member-calls4.unicity.com/media/unishop/phl/sku/31260.jpg',
                qty: 1,
                stock: '99',
                status: '1',
                allow_backorder: '1',
                remarks: '|enroll|'
            },
            {
                product_id: '31262',
                post_title: 'Starter Kit',
                pv: 514,
                member_price: 47310,
                image:
                    'https://member-calls4.unicity.com/media/unishop/phl/sku/31260.jpg',
                qty: 1,
                stock: '99',
                status: '1',
                allow_backorder: '1',
                remarks: '|enroll|'
            }
        ]
    },
    {
        name: 'vietnam',
        title: 'UNICITY (VN)',
        code: 'VN',
        currency: 'VND',
        starterKit: {
            product_id: '26021',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 200000,
            image:
                'https://s3-ap-southeast-1.amazonaws.com/ushop.unicity.com/starter_kit/starter-kit1-600x372.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'indonesia',
        title: 'UNICITY (ID)',
        code: 'ID',
        currency: 'IDN',
        starterKit: {
            product_id: '25656',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 150000,
            image: '',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'uae',
        title: 'UNICITY (AE)',
        code: 'AE',
        currency: 'AED',
        starterKit: {
            product_id: '29099',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 200000,
            image: '',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'taiwan',
        title: 'UNICITY (TW)',
        code: 'TW',
        currency: 'TWD',
        starterKit: {
            product_id: '23019',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 1000,
            image: '',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'hongkong',
        title: 'UNICITY (HK)',
        code: 'HK',
        currency: 'HKD',
        starterKit: {
            product_id: '23870',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 250,
            image:
                'https://member-calls4.unicity.com/media/unishop/hkg/sku/23870.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'cambodia',
        title: 'UNICITY (KH)',
        code: 'KH',
        currency: 'USD',
        starterKit: {
            product_id: '20817',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: '500',
            image: '',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'malaysia',
        title: 'UNICITY (MY)',
        code: 'MY',
        currency: 'MYR',
        starterKit: {
            product_id: '27027',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 200000,
            image: '',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'laos',
        title: 'UNICITY (LA)',
        code: 'LA',
        currency: 'LAK',
        starterKit: {
            product_id: '20817',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: '500',
            image: '',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'myanmar',
        title: 'UNICITY (MM)',
        code: 'MM',
        currency: 'kyat',
        starterKit: {
            product_id: '20817',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: '500',
            image: '',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'australia',
        title: 'UNICITY (AU)',
        code: 'AU',
        currency: 'aus',
        starterKit: {
            product_id: '31440',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: '500',
            image: '',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    },
    {
        name: 'newzealand',
        title: 'UNICITY (NZ)',
        code: 'NZ',
        currency: 'nzl',
        starterKit: {
            product_id: '31440',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: '500',
            image: '',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '0',
            remarks: '|enroll|'
        }
    }
]

export const shippingAddress = {
    aramexAddress: {
        address1: 'Aramax (AIC Dept.) 3 Changi South Street 1, #01-01',
        address2: 'Santa United International  Bldg.',
        mobilePhone: '6565430300',
        city: 'Singapore',
        country: 'SG',
        zip: '486795'
    },
    pickUpAddress: {
        fullName: 'Singapore Main Office',
        address1: '3 Temasek Boulevard',
        address2: '#01-351, Suntec City Mall (West Wing)',
        city: 'Singapore',
        country: 'SG',
        zip: '038983'
    },
    ortigasPickUpAddress: {
        company: 'Unicity Network Philippines, Inc.',
        fullName: 'Manila, Main Office',
        address1: 'Unit G-02, Antel Global Corporate Center',
        address2: '#3 Julia A. Vargas Avenue, Ortigas Center',
        city: 'Pasig City',
        country: 'PH',
        zip: '1605',
        comment: 'Pick-Up Ortigas'
    },
    taguigPickUpAddress: {
        fullName: 'Taguig City, Main Office',
        address1: 'Ground Floor 8/10 Upper McKinley Building',
        address2: 'Upper McKinley Road, McKinley Town Center',
        city: 'Taguig City',
        country: 'PH',
        zip: '1201',
        comment: 'Pick-Up Taguig'
    },
    pickUpAddressTHA: {
        TH: {
            fullName: 'Bangkok, Main Office',
            address1: '44/1 ชั้น 1 และชั้น 15 อาคารรุ่งโรจน์ธนกุล',
            address2: 'ถนนรัชดาภิเษก เขตห้วยขวาง',
            city: 'กรุงเทพมหานคร',
            country: 'TH',
            zip: '10310'
        },
        EN: {
            fullName: 'Bangkok, Main Office',
            address1: '44/1 Ground floor and 15 floor',
            address2: 'Rungrojthanakul Building Ratchadapisek Road, Huaykwang',
            city: 'Bangkok',
            country: 'TH',
            zip: '10310'
        }
    },
    pickUpAddressTW: {
        TW: {
            fullName: 'Bangkok, Main Office',
            address1: '44/1 ชั้น 1 และชั้น 15 อาคารรุ่งโรจน์ธนกุล',
            address2: 'ถนนรัชดาภิเษก เขตห้วยขวาง',
            city: 'กรุงเทพมหานคร',
            country: 'TW',
            zip: '10310'
        },
        EN: {
            fullName: 'Bangkok, Main Office',
            address1: '44/1 Ground floor and 15 floor',
            address2: 'Rungrojthanakul Building Ratchadapisek Road, Huaykwang',
            city: 'Bangkok',
            country: 'TH',
            zip: '10310'
        }
    },
    pickUpAddressVNM: {
        VN: {
            HANOI: {
                fullName: 'Hanoi Office',
                address1: 'Tầng 2, Tòa Nhà Việt Á, Phường Dịch Vọng Hậu',
                address2: 'Hà Nội',
                city: 'Hà Nội',
                country: 'VN',
                zip: ''
            },
            HOCHIMINH: {
                fullName: 'Ho Chi Minh Office',
                address1: 'Số 141, Đường Cộng Hòa, Phường 12',
                address2: 'Quận Tân Bình, TP Hồ Chí Minh',
                city: 'Hồ Chí Minh',
                country: 'VN',
                zip: ''
            }
        },
        EN: {
            HANOI: {
                fullName: 'Hanoi Office',
                address1: 'Floor 2, Viet A Building, Dich Vong Hau Ward',
                address2: 'Hanoi',
                city: 'Hanoi',
                country: 'VN',
                zip: ''
            },
            HOCHIMINH: {
                fullName: 'Ho Chi Minh Office',
                address1: 'No. 141, Cong Hoa Street, Ward 12',
                address2: 'Tan Binh District, Ho Chi Minh City',
                city: 'Ho Chi Minh',
                country: 'VN',
                zip: ''
            }
        }
    },

    pickUpAddressHKG: {
        HK: {
            fullName: 'Main Office',
            address1: 'Room 1118, 11/F Leighton Centre',
            address2: '77 Leighton Road',
            city: 'Causeway Bay, ',
            country: 'HK',
            zip: ''
        },
        EN: {
            fullName: 'Main Office',
            address1: 'Room 1118, 11/F Leighton Centre',
            address2: '77 Leighton Road',
            city: 'Causeway Bay',
            country: 'HK',
            zip: ''
        }
    },

    pickUpAddressIDN: {
        ID: {
            JKT: {
                fullName: 'Jakarta Office',
                address1: 'Gedung Perkantoran Bidakara 2 Lt.15 Unit 2',
                address2: 'Jl. Jend. Gatot Subroto Kav 71-7',
                city: 'Jakarta Selatan',
                country: 'ID',
                zip: '12870'
            },
            SBY: {
                fullName: 'Surabaya Office',
                address1: 'Intiland Tower Surabaya 1st floor Suite 1',
                address2: 'Jl. Panglima Sudirman No.101-103, Embong Kaliasin,',
                city: 'KEC. Genteng - Kota Surabaya - Jawa Timur',
                country: 'ID',
                zip: '60271'
            }
        },
        EN: {
            JKT: {
                fullName: 'Jakarta Office',
                address1: 'Gedung Perkantoran Bidakara 2 Lt.15 Unit 2',
                address2: 'Jl. Jend. Gatot Subroto Kav 71-7',
                city: 'Jakarta Selatan',
                country: 'ID',
                zip: '12870'
            },
            SBY: {
                fullName: 'Surabaya Office',
                address1: 'Intiland Tower Surabaya 1st floor Suite 1',
                address2: 'Jl. Panglima Sudirman No.101-103, Embong Kaliasin,',
                city: 'KEC. Genteng - Kota Surabaya - Jawa Timur',
                country: 'ID',
                zip: '60271'
            }
        }
    }
}

export const simpleShipAddress = countryCode => {
    const countriesOffice = {
        TH: 'Bangkok, Main Office',
        SG: 'Singapore, Main Office',
        AU: 'Australia, Main Office',
        NZ: 'Newzealand, Main Office'
    }
    return countriesOffice[countryCode]
}

export const ACL = {
    A: {
        description: 'Active',
        api: {
            type: 'Associate',
            status: 'Active',
            priceLevel: 'wholesale_price'
        },
        allowed: allowLogin(location, 'A'),
        showPv: true
    },
    B: {
        description: 'Pending',
        api: {
            type: 'Associate',
            status: 'Pending',
            priceLevel: 'wholesale_price'
        },
        allowed: allowLogin(location, 'B'),
        showPv: true
    },
    C: {
        description: 'Customer',
        api: {
            type: 'Customer',
            status: 'Active',
            priceLevel: 'retail_price'
        },
        allowed: allowLogin(location, 'C'),
        showPv: false
    },
    D: {
        description: 'Balance (CHE, DEU), Defective (JP)',
        api: {
            type: 'Associate',
            status: 'Defective',
            priceLevel: null
        },
        allowed: allowLogin(location, 'D'),
        showPv: false
    },
    E: {
        description: 'Employee',
        api: {
            type: 'Employee',
            status: 'Active',
            priceLevel: 'employee_price'
        },
        allowed: allowLogin(location, 'E'),
        showPv: false
    },
    F: {
        description: 'Family Pricing',
        api: {
            type: 'Family',
            status: 'Active',
            priceLevel: 'employee_price'
        },
        allowed: allowLogin(location, 'F'),
        showPv: false
    },
    H: {
        description: 'Wholesale Customer',
        api: {
            type: 'WholesaleCustomer',
            status: 'Active',
            priceLevel: 'wholesale_price'
        },
        allowed: allowLogin(location, 'H'),
        showPv: false
    },
    L: {
        description: 'Performance Labs',
        api: {
            type: 'Performance Labs',
            status: 'Active',
            priceLevel: 'wholesale_price'
        },
        allowed: allowLogin(location, 'L'),
        showPv: false
    },
    P: {
        description: 'Preferred Customer',
        api: {
            type: 'PreferredCustomer',
            status: 'Active',
            priceLevel: 'preferred_price'
        },
        allowed: allowLogin(location, 'P'),
        showPv: false
    },
    R: {
        description: 'Retired',
        api: {
            type: 'Associate',
            status: 'Retired',
            priceLevel: null
        },
        allowed: allowLogin(location, 'R'),
        showPv: false
    },
    S: {
        description: 'Suspended',
        api: {
            type: 'Associate',
            status: 'Suspended',
            priceLevel: null
        },
        allowed: allowLogin(location, 'S'),
        showPv: false
    },
    T: {
        description: 'Terminated',
        api: {
            type: 'Associate',
            status: 'Terminated',
            priceLevel: null
        },
        allowed: allowLogin(location, 'T'),
        showPv: false
    }
}

export function allowLogin(country, type) {
    let allowed = false
    if (country === 'singapore') {
        // eslint-disable-next-line default-case
        switch (type) {
            case 'A':
                allowed = 'full'
                break
            case 'B':
                allowed = 'profile'
                break
            case 'C':
                allowed = 'profile'
                break
            case 'D':
                allowed = false
                break
            case 'E':
                allowed = 'profile'
                break
            case 'F':
                allowed = false
                break
            case 'H':
                allowed = false
                break
            case 'L':
                allowed = 'profile'
                break
            case 'P':
                allowed = 'profile'
                break
            case 'R':
                allowed = false
                break
            case 'S':
                allowed = false
                break
            case 'T':
                allowed = false
                break
        }
    } else if (country === 'philippines') {
        switch (type) {
            case 'A':
                allowed = 'full'
                break
            case 'B':
                allowed = 'profile'
                break
            case 'C':
                allowed = 'profile'
                break
            case 'D':
                allowed = false
                break
            case 'E':
                allowed = 'profile'
                break
            case 'F':
                allowed = false
                break
            case 'H':
                allowed = false
                break
            case 'P':
                allowed = 'philippinesPC'
                break
            case 'R':
                allowed = false
                break
            case 'S':
                allowed = false
                break
            case 'T':
                allowed = false
                break
        }
    } else if (country === 'thailand' || country === 'korea') {
        switch (type) {
            case 'A':
                allowed = 'full'
                break
            case 'B':
                allowed = 'profile'
                break
            case 'C':
                allowed = 'profile'
                break
            case 'D':
                allowed = false
                break
            case 'E':
                allowed = 'profile'
                break
            case 'F':
                allowed = false
                break
            case 'H':
                allowed = false
                break
            case 'P':
                allowed = false
                break
            case 'R':
                allowed = false
                break
            case 'S':
                allowed = false
                break
            case 'T':
                allowed = false
                break
        }
    } else if (country === 'australia' || country === 'newzealand') {
        switch (type) {
            case 'A':
                allowed = 'full'
                break
            case 'B':
                allowed = false
                break
            case 'C':
                allowed = false
                break
            case 'D':
                allowed = false
                break
            case 'E':
                allowed = false
                break
            case 'F':
                allowed = false
                break
            case 'H':
                allowed = false
                break
            case 'P':
                allowed = false
                break
            case 'R':
                allowed = false
                break
            case 'S':
                allowed = false
                break
            case 'T':
                allowed = false
                break
        }
    } else if (country === 'japan') {
        switch (type) {
            case 'A':
                allowed = 'japanFull'
                break
            case 'B':
                allowed = false
                break
            case 'C':
                allowed = false
                break
            case 'D':
                allowed = false
                break
            case 'E':
                allowed = false
                break
            case 'F':
                allowed = false
                break
            case 'H':
                allowed = 'japanStatusH'
                break
            case 'P':
                allowed = false
                break
            case 'R':
                allowed = false
                break
            case 'S':
                allowed = false
                break
            case 'T':
                allowed = false
                break
        }
    } else if (country === 'vietnam') {
        switch (type) {
            case 'A':
                allowed = 'vietnamFull'
                break
            case 'B':
                allowed = false
                break
            case 'C':
                allowed = false
                break
            case 'D':
                allowed = false
                break
            case 'E':
                allowed = false
                break
            case 'F':
                allowed = false
                break
            case 'H':
                allowed = false
                break
            case 'P':
                allowed = false
                break
            case 'R':
                allowed = false
                break
            case 'S':
                allowed = false
                break
            case 'T':
                allowed = false
                break
        }
    } else if (
        country === 'indonesia' ||
        country === 'aue' ||
        country === 'taiwan' ||
        country === 'hongkong' ||
        country === 'malaysia'
    ) {
        switch (type) {
            case 'A':
                allowed = 'full'
                break
            case 'B':
                allowed = 'full'
                break
            case 'C':
                allowed = false
                break
            case 'D':
                allowed = false
                break
            case 'E':
                allowed = false
                break
            case 'F':
                allowed = false
                break
            case 'H':
                allowed = false
                break
            case 'P':
                allowed = false
                break
            case 'R':
                allowed = false
                break
            case 'S':
                allowed = false
                break
            case 'T':
                allowed = false
                break
        }
    } else if (
        country === 'cambodia' ||
        country === 'hongkong' ||
        country === 'taiwan' ||
        country === 'laos' ||
        country === 'myanmar'
    ) {
        switch (type) {
            case 'A':
                allowed = 'full'
                break
            case 'B':
                allowed = false
                break
            case 'C':
                allowed = false
                break
            case 'D':
                allowed = false
                break
            case 'E':
                allowed = false
                break
            case 'F':
                allowed = false
                break
            case 'H':
                allowed = false
                break
            case 'P':
                allowed = false
                break
            case 'R':
                allowed = false
                break
            case 'S':
                allowed = false
                break
            case 'T':
                allowed = false
                break
        }
    }

    if (country === 'singapore') {
        if (type === 'F') {
            allowed = 'express'
        }
    }
    return allowed
}

export function priceLevel(country, type) {
    let priceLevel = false
    if (
        country === 'singapore' ||
        country === 'thailand' ||
        country === 'philippines' ||
        country === 'malaysia'
    ) {
        switch (type) {
            case 'A':
                priceLevel = 'wholesale_price'
                break
            case 'B':
                priceLevel = 'wholesale_price'
                break
            case 'C':
                priceLevel = 'retail_price'
                break
            case 'D':
                priceLevel = null
                break
            case 'E':
                priceLevel = 'employee_price'
                break
            case 'F':
                priceLevel = null
                break
            case 'H':
                priceLevel = 'wholesale_price'
                break
            case 'P':
                priceLevel = 'preferred_price'
                break
            case 'R':
                priceLevel = null
                break
            case 'S':
                priceLevel = null
                break
            case 'T':
                priceLevel = null
                break
        }

        if (country === 'singapore' && type === 'F') {
            priceLevel = 'employee_price'
        }

        if (country === 'singapore' && type === 'E') {
            if (store.customerData && store.customerData.employmentDetails) {
                if (
                    store.customerData.employmentDetails.employerName ===
                    'Performance Labs"'
                ) {
                    priceLevel = 'wholesale_price'
                }
            }
        }
    } else if (country === 'japan') {
        priceLevel = 'wholesale_price'
    } else if (
        country === 'vietnam' ||
        country === 'indonesia' ||
        country === 'hongkong'
    ) {
        switch (type) {
            case 'A':
                priceLevel = 'wholesale_price'
                break
            case 'B':
                priceLevel = null
                break
            case 'C':
                priceLevel = null
                break
            case 'D':
                priceLevel = null
                break
            case 'E':
                priceLevel = 'employee_price'
                break
            case 'F':
                priceLevel = null
                break
            case 'H':
                priceLevel = null
                break
            case 'P':
                priceLevel = null
                break
            case 'R':
                priceLevel = null
                break
            case 'S':
                priceLevel = null
                break
            case 'T':
                priceLevel = null
                break
        }
    } else if (
        country === 'cambodia' ||
        country === 'laos' ||
        country === 'myanmar' ||
        country === 'taiwan'
    ) {
        switch (type) {
            case 'A':
                priceLevel = 'wholesale_price'
                break
            case 'B':
                priceLevel = null
                break
            case 'C':
                priceLevel = null
                break
            case 'D':
                priceLevel = null
                break
            case 'E':
                priceLevel = null
                break
            case 'F':
                priceLevel = null
                break
            case 'H':
                priceLevel = null
                break
            case 'P':
                priceLevel = null
                break
            case 'R':
                priceLevel = null
                break
            case 'S':
                priceLevel = null
                break
            case 'T':
                priceLevel = null
                break
        }
    }

    return priceLevel
}

export const AllowList = {
    full: true,
    public: {
        sideBar: [
            'home',
            'products',
            'product_catalogue',
            'shopping',
            'enroll',
            'feedback',
            'contact_us'
        ],
        header: ['home', 'shopping', 'enroll', 'contact_us']
    },
    public2: {
        sideBar: ['home', 'shopping', 'feedback', 'contact_us'],
        header: ['home', 'shopping', 'feedback', 'contact_us']
    },
    profile: {
        sideBar: [
            'credit_balance_default_data',
            'home',
            'shopping',
            'enroll',
            'feedback',
            'dashboard',
            'logout',
            'contact_us',
            'order_history'
        ],
        header: ['home', 'shopping', 'feedback', 'contact_us']
    },
    japanFull: {
        sideBar: [
            'home',
            'shopping',
            'enroll',
            'genealogy',
            'success',
            'reports',
            'autoship',
            'dashboard',
            'logout',
            'news',
            'nfr',
            'contact_us'
        ],
        header: ['home', 'shopping', 'feedback', 'contact_us']
    },
    japanStatusH: {
        sideBar: [
            'home',
            'shopping',
            'orderhistory',
            'feedback',
            'autoship',
            'dashboard',
            'logout',
            'nfr',
            'contact_us'
        ],
        header: ['home', 'shopping', 'feedback', 'contact_us']
    },
    vietnamFull: {
        sideBar: [
            'home',
            'shopping',
            'enroll',
            'genealogy',
            'success',
            'commission',
            'reports',
            'feedback',
            'dashboard',
            'logout',
            'contact_us',
            'seminar'
        ],
        header: ['home', 'shopping', 'feedback', 'contact_us']
    },
    philippinesFull: {
        sideBar: [
            'home',
            'shopping',
            'enroll',
            'genealogy',
            'success',
            'commission',
            'reports',
            'product_catalogue',
            'feedback',
            'dashboard',
            'logout',
            'contact_us'
        ],
        header: ['home', 'shopping', 'feedback', 'contact_us']
    },
    indonesiaFull: {
        sideBar: [
            'home',
            'shopping',
            'enroll',
            'genealogy',
            'success',
            'commission',
            'reports',
            'feedback',
            'dashboard',
            'logout',
            'contact_us',
            'news'
        ],
        header: ['home', 'shopping', 'enroll', 'contact_us']
    },
    express: {
        sideBar: [
            'home',
            'shopping',
            'feedback',
            'contact_us',
            'dashboard',
            'logout'
        ],
        header: ['home', 'shopping', 'feedback', 'contact_us']
    },
    philippinesPC: {
        sideBar: ['home', 'shopping', 'feedback', 'dashboard', 'logout'],
        header: ['home', 'shopping']
    },
    dtoken: {
        sideBar: ['home', 'shopping', 'contact_us'],
        header: ['home', 'shopping', 'contact_us']
    },
    inPageMenu: {
        profile: profileMenu(),
        express: profileMenu(location),
        philippinesPC: profileMenu(),
        japanFull: profileMenu(),
        japanStatusH: profileMenu(),
        vietnamFull: profileMenu(),
        philippinesFull: profileMenu(),
        indonesiaFull: profileMenu()
    }
}

export function fetchProfileMenu() {
    AllowList.inPageMenu.profile = profileMenu()
    AllowList.inPageMenu.express = profileMenu()
    AllowList.inPageMenu.japanFull = profileMenu()
    AllowList.inPageMenu.japanStatusH = profileMenu()
    AllowList.inPageMenu.vietnamFull = profileMenu()
    AllowList.inPageMenu.philippinesFull = profileMenu()
    AllowList.inPageMenu.philippinesPC = profileMenu()

    AllowList.inPageMenu.indonesiaFull = profileMenu()
    return AllowList
}

export function profileMenu() {
    if (sessionStorage.getItem('customerData')) {
        return [
            // {
            //     title: 'Profile',
            //     link: 'profile',
            //     icon: 'icon-head',
            //     allowed: ['full', 'profile','express','japanFull','japanStatusH','vietnamFull','indonesiaFull','philippinesFull','philippinesPC'],
            //     countries: true,
            //     userCountry: true
            // },
            // {
            //     title: 'Order History',
            //     link: 'orderhistory',
            //     icon: 'icon-bag',
            //     allowed: ['full', 'profile','express','japanFull','japanStatusH','vietnamFull','indonesiaFull','philippinesFull','philippinesPC'],
            //     countries: true,
            //     userCountry: true
            // },
            // {
            //     title: 'Commission',
            //     link: 'commission',
            //     icon: 'fas fa-dollar-sign',
            //     allowed: ['full','vietnamFull','indonesiaFull', 'japanFull','philippinesFull'],
            //     countries: true,
            //     userCountry: true
            // },

            {
                title: 'BIR',
                link: 'bir',
                icon: 'fa fa-line-chart',
                allowed: ['full'],
                countries: ['PH'],
                userCountry: ['PH']
            },
            {
                title: 'Build a cart', // Share a cart, easy shop
                link: 'shopprofile',
                icon: 'fas fa-shopping-basket',
                allowed: ['full'],
                countries: ['SG', 'TH', 'AU', 'NZ'],
                userCountry: ['SG', 'TH', 'AU', 'NZ']
            },
            {
                title: 'Referral',
                link: 'referral',
                icon: 'fa fa-link',
                allowed: ['full'],
                countries: ['AE'],
                userCountry: ['AE']
            },
            {
                title: 'PCC Member',
                link: 'pccmember',
                icon: 'fa fa-download',
                allowed: ['japanStatusH'],
                countries: ['JP'],
                userCountry: ['JP']
            },
            {
                title: 'AutoShip',
                link: 'autoship',
                icon: 'fab fa-gg',
                allowed: ['japanFull', 'japanStatusH'],
                countries: ['JP'],
                userCountry: ['JP']
            },
            {
                title: 'LSB',
                link: 'lsb',
                icon: 'fas fa-money-bill',
                allowed: ['full'],
                countries: ['TH', 'VN', 'AE'],
                userCountry: ['SG', 'TH', 'VN', 'AE']
            },
            {
                title: 'Upload Document',
                link: 'uploaddocument',
                icon: 'fas fa-upload',
                allowed: ['full', 'profile'],
                countries: ['TH'],
                userCountry: ['TH']
            },
            {
                title: 'Pc Referral-link',
                link: 'pc_referral_link',
                icon: 'icon-head',
                allowed: ['full'],
                countries: ['PH'],
                userCountry: ['PH']
            }
        ]
    } else {
        return []
    }
}

export function HeaderMenu() {
    return [
        {
            key: 'home',
            title: dictionary('home').toUpperCase(),
            path: `/`,
            children: [],
            auth: false
        },
        {
            key: 'shopping',
            title: dictionary('shopping').toUpperCase(),
            path: `/catproduct/all`,
            children: [],
            auth: false,
            exceptCountry: ['KH', 'LA']
        },
        {
            key: 'enroll',
            title: dictionary('enroll').toUpperCase(),
            path: `/enroll`,
            children: [],
            auth: false,
            countries: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'AE',
                'JP',
                'IN',
                'US',
                'BH',
                'HK',
                'AU',
                'NZ',
                'TW'
            ]
        },
        {
            key: 'contact_us',
            title: dictionary('contact_us').toUpperCase(),
            path: `/contactus`,
            children: [],
            auth: false
        },
        {
            key: 'feedback',
            title: dictionary('feedback').toUpperCase(),
            path: `/feedback`,
            children: [],
            auth: false
        }
    ]
}

const MENA_COUNTRY = [
    'AE',
    'DZ',
    'BH',
    'EG',
    'IR',
    'IQ',
    'IL',
    'JO',
    'KW',
    'LB',
    'LY',
    'MA',
    'OM',
    'PS',
    'QA',
    'SA',
    'SY',
    'TN',
    'YE'
]

export function Menu() {
    return [
        {
            key: 'home',
            title: dictionary('home').toUpperCase(),
            path: `/`,
            children: [],
            auth: false,
            countries: true,
            userCountry: true
        },
        {
            key: 'product_catalogue',
            title: 'product_catalogue',
            path: null,
            children: [],
            auth: {
                PH: false,
                SG: true
            },
            countries: ['PH', 'SG'],
            userCountry: ['PH', 'SG'],
            externalLink: {
                link: language.isEnglish()
                    ? Country.isSingapore()
                        ? dictionary('product_catalogue_sg')
                        : dictionaryNative('product_catalogue_link_en')
                    : dictionaryNative('product_catalogue_link_native'),
                target: 'blank'
            }
        },
        {
            key: 'shopping',
            title: dictionary('shopping').toUpperCase(),
            path: `/catproduct/all`,
            children: [],
            auth: false,
            countries: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'JP',
                'IN',
                'US',
                'TW',
                'HK',
                'CN',
                'AU',
                'NZ',
                'KH'
            ].concat(MENA_COUNTRY),
            userCountry: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'JP',
                'IN',
                'US',
                'TW',
                'HK',
                'CN',
                'AU',
                'NZ',
                'KH'
            ].concat(MENA_COUNTRY)
        },
        {
            key: 'orderhistory',
            title: dictionary('orderhistory').toUpperCase(),
            path: `/orderhistory`,
            children: [],
            auth: true,
            countries: ['JP'],
            userCountry: ['JP']
        },
        {
            key: 'products',
            title: dictionary('products').toUpperCase(),
            path: `/catproduct/all`,
            children: [
                {
                    title: 'Card',
                    path: `/catproduct/all/card`,
                    children: [],
                    auth: true,
                    key: 'card'
                },
                {
                    title: 'A-Z',
                    path: `/catproduct/all/aiiz`,
                    children: [],
                    auth: true,
                    key: 'aiiz'
                }
            ],
            auth: false,
            countries: ['KH', 'LA', 'MM'],
            userCountry: ['KH', 'LA', 'MM']
        },
        {
            key: 'enroll',
            title: dictionary('enroll').toUpperCase(),
            path: `/enroll`,
            children: [],
            auth: false,
            countries: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'AE',
                'JP',
                'IN',
                'US',
                'BH',
                'HK',
                'CN',
                'AU',
                'NZ',
                'KH'
            ],
            userCountry: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'AE',
                'JP',
                'IN',
                'US',
                'BH',
                'HK',
                'CN',
                'AU',
                'NZ',
                'KH'
            ]
        },
        {
            key: 'genealogy',
            title: dictionary('genealogy').toUpperCase(),
            path: `/genealogy`,
            children: [],
            auth: true,
            countries: true,
            userCountry: true
        },
        {
            key: 'success',
            title: dictionary('success').toUpperCase(),
            path: `/success`,
            children: [],
            auth: true,
            countries: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'AE',
                'JP',
                'IN',
                'US',
                'BH',
                'HK',
                'AU',
                'NZ',
                'KH',
                'AT',
                'BN',
                'CH',
                'CO',
                'DE',
                'IE',
                'KR',
                'MN',
                'MY',
                'TW',
                'BE',
                'LA',
                'CA',
                'LU',
                'DK',
                'DZ',
                'EG',
                'ES',
                'FR',
                'GB',
                'GC',
                'JO',
                'KW',
                'NO',
                'OM',
                'PL',
                'QA',
                'SA',
                'SE',
                'TN',
                'YE'
            ],
            userCountry: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'AE',
                'JP',
                'IN',
                'US',
                'BH',
                'HK',
                'AU',
                'NZ',
                'KH',
                'AT',
                'BN',
                'CH',
                'CO',
                'DE',
                'IE',
                'KR',
                'MN',
                'MY',
                'TW',
                'BE',
                'LA',
                'CA',
                'LU',
                'DK',
                'DZ',
                'EG',
                'ES',
                'FR',
                'GB',
                'GC',
                'JO',
                'KW',
                'NO',
                'OM',
                'PL',
                'QA',
                'SA',
                'SE',
                'TN',
                'YE'
            ]
        },
        {
            key: 'nfr',
            title: dictionary('nfr').toUpperCase(),
            path: `/nfr`,
            children: [],
            auth: true,
            countries: ['JP'],
            userCountry: ['JP']
        },
        {
            key: 'commission',
            title: dictionary('commission').toUpperCase(),
            path: `/profile/commission`,
            children: [],
            auth: true,
            countries: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'AE',
                'TW',
                'HK',
                'AU',
                'NZ',
                'KH'
            ],
            userCountry: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'AE',
                'TW',
                'HK',
                'AU',
                'NZ',
                'KH'
            ]
        },
        {
            key: 'award_plan_summary',
            title: 'award_plan_summary',
            path: `/award-plan-summary`,
            children: [],
            auth: false,
            countries: ['ID'],
            userCountry: ['ID']
        },
        {
            key: 'program_and_promotion_summary',
            title: 'program_and_promotion_summary',
            path: `/program-and-promotion-summary`,
            children: [],
            auth: false,
            countries: ['ID'],
            userCountry: ['ID']
        },
        {
            key: 'news',
            title: dictionary('news').toUpperCase(),
            path: `/news`,
            children: [],
            auth: true,
            countries: ['JP', 'ID'],
            userCountry: ['JP', 'ID']
        },
        {
            key: 'reports',
            title: dictionary('reports').toUpperCase(),
            path: `/reports`,
            children: [],
            auth: true,
            countries: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'AE',
                'JP',
                'IN',
                'US',
                'BH',
                'HK',
                'AU',
                'NZ',
                'KH',
                'TW'
            ],
            userCountry: [
                'SG',
                'PH',
                'TH',
                'VN',
                'ID',
                'AE',
                'JP',
                'IN',
                'US',
                'BH',
                'HK',
                'AU',
                'NZ',
                'KH',
                'TW'
            ]
        },
        {
            key: 'dashboard',
            title: dictionary('profile').toUpperCase(),
            path: `/profile/profile`,
            children: [],
            auth: true,
            countries: true,
            userCountry: true
        },
        {
            key: 'seminar',
            title: dictionary('seminar').toUpperCase(),
            path: `/seminar`,
            children: [],
            auth: true,
            countries: [
                'SG',
                'PH',
                'TH',
                'VN',
                'AE',
                'JP',
                'KH',
                'LA',
                'MM',
                'AU',
                'NZ',
                'TW'
            ],
            userCountry: [
                'SG',
                'PH',
                'TH',
                'VN',
                'AE',
                'JP',
                'KH',
                'LA',
                'MM',
                'AU',
                'NZ',
                'TW'
            ]
        },
        {
            key: 'autoship',
            title: dictionary('autoship').toUpperCase(),
            path: `/autoship`,
            children: [],
            auth: true,
            countries: ['JP'],
            userCountry: ['JP']
        },
        {
            key: 'marketing_artworks',
            title: dictionary('u_all_sidebar_marketing_artworks'),
            path: `/marketing_artworks`,
            auth: true,
            countries: ['TH'],
            userCountry: ['TH']
        },
        {
            key: 'feedback',
            title: dictionary('feedback').toUpperCase(),
            path: `/`,
            children: [
                {
                    title: '',
                    path: '',
                    children: [],
                    auth: true
                }
            ],
            auth: false,
            countries: true,
            userCountry: true
        },
        {
            key: 'contact_us',
            title: dictionary('contact_us').toUpperCase(),
            path: `/contactus`,
            children: [],
            auth: false,
            countries: true,
            userCountry: true
        },
        {
            key: 'logout',
            title: dictionary('logout').toUpperCase(),
            path: '/logout',
            children: [],
            auth: true,
            countries: true,
            userCountry: true
        }
    ]
}

function viewBaInfoList(country, isLoginUser) {
    // const infoList = {
    //     singapore: [
    //         'bookmarks',
    //         'member_id',
    //         'vip',
    //         'address',
    //         'city',
    //         'enroller_id',
    //         'enroller_name',
    //         'sponsor_id',
    //         'sponsor_name',
    //         'enrolled',
    //         'status',
    //         'highest_rank'
    //     ],
    //     thailand: [
    //         'bookmarks',
    //         'member_id',
    //         'vip',
    //         'email',
    //         'phone_no',
    //         'workPhone',
    //         'homePhone',
    //         'address',
    //         'city',
    //         'enroller_id',
    //         'enroller_name',
    //         'sponsor_id',
    //         'sponsor_name',
    //         'enrolled',
    //         'expiry',
    //         'status',
    //         'highest_rank',
    //         'expiry'
    //     ],
    //     japan: [
    //         'bookmarks',
    //         //'member_id',
    //         //'vip',
    //         'address',
    //         'city',
    //         'sponsor_name',
    //         'enrolled',
    //         'status',
    //         'highest_rank'
    //     ],
    //     vietnam: [
    //         'bookmarks',
    //         'member_id',
    //         'vip',
    //         'address',
    //         'city',
    //         'enroller_id',
    //         'enroller_name',
    //         'sponsor_id',
    //         'sponsor_name',
    //         'enrolled',
    //         'status',
    //         'highest_rank'
    //     ],
    //     indonesia: [
    //         'bookmarks',
    //         'member_id',
    //         'vip',
    //         'email',
    //         'phone_no',
    //         'workPhone',
    //         'homePhone',
    //         'address',
    //         'city',
    //         'enroller_id',
    //         'enroller_name',
    //         'sponsor_id',
    //         'sponsor_name',
    //         'enrolled',
    //         'status',
    //         'highest_rank'
    //     ],
    //     australia: [
    //         'bookmarks',
    //         'member_id',
    //         'vip',
    //         'address',
    //         'city',
    //         'enroller_id',
    //         'enroller_name',
    //         'sponsor_id',
    //         'sponsor_name',
    //         'enrolled',
    //         'status',
    //         'highest_rank'
    //     ],
    //     newzealand: [
    //         'bookmarks',
    //         'member_id',
    //         'vip',
    //         'address',
    //         'city',
    //         'enroller_id',
    //         'enroller_name',
    //         'sponsor_id',
    //         'sponsor_name',
    //         'enrolled',
    //         'status',
    //         'highest_rank'
    //     ],
    //     default: [
    //         'bookmarks',
    //         'member_id',
    //         'vip',
    //         'email',
    //         'phone_no',
    //         'workPhone',
    //         'homePhone',
    //         'address',
    //         'city',
    //         'enroller_id',
    //         'enroller_name',
    //         'sponsor_id',
    //         'sponsor_name',
    //         'enrolled',
    //         'status',
    //         'highest_rank'
    //     ]
    // }

    const infoListForAuthUser = {
        default: [
            'enroller_id',
            'enroller_name',
            'sponsor_id',
            'sponsor_name',
            'address',
            'email',
            'phone_no',
            'enrolled',
            'status'
        ],
        japan: [
            'sponsor_id',
            'sponsor_name',
            'address',
            'email',
            'phone_no',
            'enrolled',
            'renewal_date',
            'status'
        ]
    }

    const infoListForDownlineUser = {
        default: [
            'enroller_id',
            'enroller_name',
            'sponsor_id',
            'sponsor_name',
            'enrolled',
            'status'
        ],
        japan: [
            'sponsor_id',
            'sponsor_name',
            'enrolled',
            'renewal_date',
            'status'
        ]
    }

    if (isLoginUser) {
        return infoListForAuthUser[country] === undefined
            ? infoListForAuthUser.default
            : infoListForAuthUser[country]
    } else {
        return infoListForDownlineUser[country] === undefined
            ? infoListForDownlineUser.default
            : infoListForDownlineUser[country]
    }

    // return infoList[country] === undefined ? infoList.default : infoList[country]
}

export const showViewBaInfo = (countryCode, isAuthUser) => {
    let country = countryCode
        ? convertCountryString(countryCode, 'alpha-2', 'name').toLowerCase()
        : 'default'

    let c = viewBaInfoList(country, isAuthUser)

    return c
}

export const inArray = (needle, arrayList) => {
    return $.inArray(needle, arrayList) > -1 ? true : false
}

export const legBars = countryCode => {
    const legs = {
        basic: [
            {
                key: 'pv',
                name: 'PV',
                background: '#2F80ED',
                value: 0,
                requiredValue: 0
            },
            {
                key: 'tv',
                name: 'TV',
                background: '#BB6BD9',
                customRender: dic => {
                    return dic('tv') + ' - ' + dic('compressed_tv')
                },
                value: 0,
                requiredValue: 0
            },
            {
                key: 'compressed_tv',
                name: 'TV',
                background: '#ff94ed',
                extraKey: 'dateCalculated',
                showButton: false,
                value: 0,
                requiredValue: 0
            },
            {
                key: 'active_legs',
                name: 'active_legs',
                background: '#ff94ed',
                showButton: false
            },
            {
                key: 'ov_apt1',
                presetKey: 'apt',
                name: 'APT',
                background: '#F987A5',
                showFullName: true,
                openLeg: 4,
                showButton: true,
                value: 0,
                requiredValue: 0
            },
            {
                key: 'apv',
                name: 'autoship_pv',
                background: '#ff94ed',
                showButton: false
            }
        ],
        ccm_selected: [
            {
                key: 'ov_leg1',
                presetKey: 'ov_leg1_apt_excluded',
                name: 'Leg 1',
                background: '#F2994A',
                showFullName: true,
                openLeg: null,
                value: 0,
                requiredValue: 0
            },
            {
                key: 'ov_leg2',
                presetKey: 'ov_leg2_apt_excluded',
                name: 'Leg 2',
                background: '#F2C94C',
                showFullName: true,
                openLeg: null,
                value: 0,
                requiredValue: 0
            },
            {
                key: 'ov_leg3hc_pv_excluded',
                presetKey: 'ov_leg3hc_apt_excluded',
                name: 'Leg 3',
                background: '#B7E250',
                showFullName: false,
                openLeg: 'ccm-3',
                value: 0,
                requiredValue: 0
            },
            {
                key: 'ov_apt1',
                presetKey: 'apt',
                name: 'APT',
                background: '#F987A5',
                showFullName: true,
                openLeg: 4,
                showButton: false
            }
        ],
        ccm_not_selected: [
            {
                key: 'ov_leg1',
                presetKey: 'leg1',
                name: 'Leg 1',
                background: '#F2994A',
                showFullName: true,
                openLeg: 1,
                value: 0,
                requiredValue: 0
            },
            {
                key: 'ov_leg2',
                presetKey: 'leg2',
                name: 'Leg 2',
                background: '#F2C94C',
                showFullName: true,
                openLeg: 2,
                value: 0,
                requiredValue: 0
            },
            {
                key: 'ov_leg3hc_pv_excluded',
                presetKey: 'ov_leg3hc',
                name: 'Leg 3',
                background: '#B7E250',
                showFullName: true,
                openLeg: 3,
                value: 0,
                requiredValue: 0
            },
            {
                key: 'ov_apt1',
                presetKey: 'apt',
                name: 'APT',
                background: '#F987A5',
                showFullName: true,
                openLeg: 4,
                showButton: false
            }
        ]
    }

    return legs
}

/** Control AddressBook form on Checkout page here. */
export const addressOrderFormConfig = (form, country) => {
    const list = {
        name: { all: true },
        country: { all: true },
        address1: { all: true },
        address2: { all: true },
        district: {
            indonesia: true
        },
        city: {
            australia: true,
            newzealand: true
        },
        state: {
            newzealand: true
        },
        province: {
            cambodia: true,
            indonesia: true,
            philippines: true,
            thailand: true,
            hongkong: true
        },
        landmark: {
            thailand: false
        },
        comment: {
            indonesia: true,
            singapore: true,
            hongkong: true,
            thailand: true
        },
        zip: {
            all: true
        },
        mobile: { all: true },
        email: { all: true }
    }

    const field = list[form]
    if (isNullOrUndefined(field)) {
        return false
    } else {
        if (isNullOrUndefined(field[country])) {
            return isNullOrUndefined(field['all']) ? false : field['all']
        } else {
            return field[country]
        }
    }
}

/** Control AddressBook form on Profile page here. */
export const addressbookFormConfig = (form, country) => {
    const list = {
        name: { all: true },
        country: { all: true },
        address1: { all: true },
        address2: { all: true },
        district: {
            indonesia: true
        },
        city: {
            australia: true,
            newzealand: true
        },
        state: {
            newzealand: true
        },
        province: {
            cambodia: true,
            indonesia: true,
            philippines: true,
            thailand: true,
            vietnam: true
        },
        landmark: {},
        comment: {},
        zip: { all: true }
    }

    const field = list[form]
    if (isNullOrUndefined(field)) {
        return false
    } else {
        if (isNullOrUndefined(field[country])) {
            return isNullOrUndefined(field['all']) ? false : field['all']
        } else {
            return field[country]
        }
    }
}

/** Control AddressBook form on Profile page here. */
export const addressProfileFormArrangementConfig = country => {
    const list = {
        thailand: [
            'name',
            'address1_th',
            'address2_th',
            'district',
            'city',
            'province',
            'state',
            'comment',
            'landmark',
            'country',
            'postal',
            'zip'
        ],
        indonesia: [
            'name',
            'address1',
            'address2_id',
            'district',
            'city',
            'province',
            'state',
            'comment',
            'landmark',
            'country',
            'postal',
            'zip'
        ]
    }

    const defaultSetting = [
        'name',
        'address1',
        'address2',
        'district',
        'city',
        'province',
        'state',
        'comment',
        'landmark',
        'country',
        'postal',
        'zip'
    ]

    const arrangement = list[country]
    if (isNullOrUndefined(arrangement)) {
        return defaultSetting
    } else {
        return arrangement
    }
}

/** Control AddressBook form on Checkout page here. */
export const addressCheckoutFormArrangementConfig = country => {
    const list = {
        thailand: [
            'name',
            'country',
            'address1_th',
            'address2_th',
            'city_th',
            'zip',
            'mobile',
            'email'
        ],
        newzealand: [
            'name_nz',
            'country',
            'address1_nz',
            'address2_nz',
            'city_nz',
            'state_nz',
            'zip_nz',
            'mobile_nz',
            'email'
        ],
        australia: [
            'name_au',
            'country',
            'address1_au',
            'address2_au',
            'city_au',
            'zip_au',
            'mobile_au',
            'email'
        ],
        singapore: [
            'name_sg',
            'country',
            'address1_sg',
            'address2_sg',
            'comment',
            'zip_sg',
            'mobile_sg',
            'email'
        ]
    }

    const defaultSetting = [
        'name',
        'country',
        'address1',
        'address2',
        'district',
        'city',
        'province',
        'state',
        'comment',
        'landmark',
        'zip',
        'mobile',
        'email'
    ]

    const arrangement = list[country]
    if (isNullOrUndefined(arrangement)) {
        return defaultSetting
    } else {
        return arrangement
    }
}

export const selfProfileForm = countryCode => {
    let country = {
        SG: false,
        MY: false,
        TH: true,
        JP: true,
        EN: false,
        PH: true,
        MY: false,
        VN: true,
        CN: false,
        ID: true,
        AE: true
    }
    return country[countryCode]
}

export const showAutoShipPv = countryCode => {
    let country = {
        SG: false,
        MY: false,
        TH: false,
        JP: true,
        PH: true,
        EN: false,
        MY: false,
        VN: false,
        CN: false,
        ID: false,
        AE: false,
        US: false
    }
    return country[countryCode]
}

export const genealogyColumn = (column, userCountryCode) => {
    const columns = {
        vip: {
            SG: true,
            MY: true,
            TH: true,
            JP: false,
            EN: false,
            PH: true,
            MY: true,
            VN: true,
            CN: false,
            TW: false,
            AE: false,
            ID: true,
            AU: false,
            NZ: false,
            KH: true
        },
        autoShip: {
            SG: false,
            TH: false,
            JP: false,
            PH: true,
            EN: false,
            MY: true,
            VN: false,
            CN: false,
            AE: false,
            ID: false,
            AU: false,
            NZ: false,
            KH: false,
            TW: false
        },
        tripPoint: {
            SG: false,
            MY: false,
            TH: false,
            PH: false,
            JP: false,
            EN: false,
            MY: false,
            VN: false,
            CN: false,
            AE: false,
            ID: false,
            AU: false,
            NZ: false,
            KH: false,
            TW: false
        },
        cpv: {
            SG: false,
            MY: false,
            TH: false,
            JP: true,
            EN: false,
            PH: false,
            MY: false,
            VN: false,
            CN: false,
            AE: false,
            ID: false,
            AU: false,
            NZ: false,
            KH: false,
            TW: false
        }
    }

    return columns[column][userCountryCode]
}

export const hasSubScription = country => {
    let c = {
        SG: true,
        TH: true,
        PH: true,
        JP: false,
        EN: true,
        MY: true,
        VN: true,
        CN: true,
        AE: true,
        ID: true,
        AU: true,
        NZ: true,
        TW: false
    }
    return c[country]
}

export const momentLocale = (country, language) => {
    const defaultLocale = 'en'
    const locales = {
        australia: { EN: 'en-au' },
        cambodia: { EN: defaultLocale, KH: 'km' },
        hongkong: { EN: defaultLocale, CN: 'zh-hk', HK: 'zh-hk' },
        indonesia: { EN: defaultLocale, ID: 'id' },
        japan: { EN: defaultLocale, JP: 'ja' },
        malaysia: { EN: defaultLocale },
        myanmar: { EN: defaultLocale, MY: 'ms-my' },
        newzealand: { EN: 'en-nz' },
        philippines: { EN: defaultLocale, PH: defaultLocale },
        singapore: { EN: defaultLocale },
        thailand: { EN: defaultLocale, TH: 'th' },
        vietnam: { EN: defaultLocale, VN: 'vi' },
        taiwan: { EN: defaultLocale, TW: 'zh-tw' },
        korea: { EN: defaultLocale, KR: 'ko' }
    }
    return isNullOrUndefined(locales[country])
        ? defaultLocale
        : isNullOrUndefined(locales[country][language])
        ? defaultLocale
        : locales[country][language]
}

export const momentDateFormat = (options = {}) => {
    let country = Country.getPath()
    let language = getStoreLanguage()
    if (isUndefined(options.country) === false) {
        country = options.country
    }
    if (isUndefined(options.language) === false) {
        language = options.language
    }

    const defaultFormat = 'DD MMM YYYY'
    const format = {
        australia: { EN: defaultFormat },
        cambodia: { EN: defaultFormat, KH: defaultFormat },
        hongkong: { EN: defaultFormat, CN: defaultFormat, HK: defaultFormat },
        indonesia: { EN: defaultFormat, ID: defaultFormat },
        japan: { EN: defaultFormat, JP: 'DD日 M月 YYYY年' },
        malaysia: { EN: defaultFormat },
        myanmar: { EN: defaultFormat, MY: defaultFormat },
        newzealand: { EN: defaultFormat },
        philippines: { EN: defaultFormat, PH: defaultFormat },
        singapore: { EN: defaultFormat },
        thailand: { EN: defaultFormat, TH: defaultFormat },
        vietnam: { EN: defaultFormat, VN: 'DD MMMM YYYY' },
        taiwan: { EN: defaultFormat, TW: defaultFormat },
        korea: { EN: defaultFormat, KR: defaultFormat }
    }
    return isNullOrUndefined(format[country])
        ? defaultFormat
        : isNullOrUndefined(format[country][language])
        ? defaultFormat
        : format[country][language]
}

export const momentTimeFormat = (options = {}) => {
    let country = Country.getPath()
    let language = getStoreLanguage()
    if (isUndefined(options.country) === false) {
        country = options.country
    }
    if (isUndefined(options.language) === false) {
        language = options.language
    }

    let defaultFormat = 'hh:mm A'
    let separator = ':'

    const listFormat = {
        australia: { EN: defaultFormat },
        cambodia: { EN: defaultFormat, KH: defaultFormat },
        hongkong: { EN: defaultFormat, CN: defaultFormat, HK: defaultFormat },
        indonesia: { EN: defaultFormat, ID: defaultFormat },
        japan: { EN: defaultFormat, JP: 'HH:mm' },
        malaysia: { EN: defaultFormat },
        myanmar: { EN: defaultFormat, MY: defaultFormat },
        newzealand: { EN: defaultFormat },
        philippines: { EN: defaultFormat, PH: defaultFormat },
        singapore: { EN: defaultFormat },
        thailand: { EN: defaultFormat, TH: defaultFormat },
        vietnam: { EN: defaultFormat, VN: defaultFormat },
        taiwan: { EN: defaultFormat, TW: defaultFormat },
        korea: { EN: defaultFormat, KR: defaultFormat }
    }

    let format = isNullOrUndefined(listFormat[country])
        ? defaultFormat
        : isNullOrUndefined(listFormat[country][language])
        ? defaultFormat
        : listFormat[country][language]

    if (isUndefined(options.format) === false) {
        if (options.format === '12') {
            format = format.replace('HH', 'hh')
        } else if (options.format === '24') {
            format = format.replace('hh', 'HH')
        }
    }

    if (isUndefined(options.second) === false) {
        if (options.second === true) {
            if (isWordExist(format, `${separator}ss`) === false) {
                if (isWordExist(format, 'mm')) {
                    format = format.replace('mm', `mm${separator}ss`)
                }
            }
        } else {
            if (isWordExist(format, `${separator}ss`)) {
                format = format.replace(`${separator}ss`, '')
            }
        }
    }

    if (isUndefined(options.separator) === false) {
        format = format.replace(separator, options.separator)
    }

    if (isUndefined(options.meridiem) === false) {
        format = format.replace('A', '')
    }

    format = format.trim()

    return format
}

export const fullDateFormat = country => {
    let fullDate = {
        SG: 'DD MMM YYYY',
        TH: 'DD MMM YYYY',
        PH: 'DD MMM YYYY',
        JP: 'DD日 M月 YYYY年',
        EN: 'DD MMM YYYY',
        MY: 'DD MMM YYYY',
        VN: 'DD MMMM YYYY',
        CN: 'DD MMM YYYY',
        AE: 'DD MMM YYYY',
        ID: 'DD MMM YYYY',
        TW: 'DD MMM YYYY',
        HK: 'DD MMM YYYY',
        KH: 'DD MMM YYYY',
        LA: 'DD MMM YYYY',
        MM: 'DD MMM YYYY',
        TW: 'DD MMM YYYY',
        KR: 'YYYY-MM-DD'
    }

    return fullDate[country]
}

export const monthYearFormat = language => {
    let monthYear = {
        SG: 'YYYY-MMM', // MM = 01, 02 ; MMM = JAN, NOV
        TH: 'YYYY-MM',
        PH: 'YYYY-MM',
        JP: 'YYYY-MMM', // MMM = 1月, 2月
        EN: 'YYYY-MM',
        MY: 'YYYY-MM',
        VN: 'YYYY-MM',
        CN: 'YYYY-MM',
        AE: 'YYYY-MM',
        ID: 'YYYY-MM',
        TW: 'YYYY-MM',
        HK: 'YYYY-MM',
        KH: 'YYYY-MM',
        LA: 'YYYY-MM',
        MM: 'YYYY-MM',
        TW: 'YYYY-MM',
        KR: 'YYYY-MM'
    }

    return monthYear[language]
}

export const rankNames = (rank, language) => {
    const ranks = [
        '-',
        '-',
        '-',
        dictionary('Mgr'),
        dictionary('SrM'),
        dictionary('ExM'),
        dictionary('Dir'),
        dictionary('SrD'),
        dictionary('ExD'),
        dictionary('PrD'),
        dictionary('PrS'),
        dictionary('PrR'),
        dictionary('DIA')
    ]

    return ranks[rank]
}

export const fullRanks = () => {
    return [
        '-',
        'Mgr',
        'SrM',
        'ExM',
        'Dir',
        'SrD',
        'ExD',
        'PrD',
        'PrS',
        'PrR',
        'DIA'
    ]
}

export const TimeZones = country => {
    const timeZones = {
        thailand: 'Asia/Bangkok',
        singapore: 'Asia/Singapore',
        japan: 'Asia/Tokyo',
        malaysia: 'Asia/Kuala_Lumpur',
        philippines: 'Asia/Manila',
        vietnam: 'Asia/Ho_Chi_Minh',
        indonesia: 'Asia/Jakarta',
        uae: 'Asia/Dubai',
        taiwan: 'Asia/Taipei',
        hongkong: 'Asia/Hong_Kong',
        cambodia: 'Asia/Bangkok',
        laos: 'Asia/Bangkok',
        myanmar: 'Asia/Bangkok',
        australia: 'Australia/Sydney',
        newzealand: 'Pacific/Auckland',
        korea: 'Asia/Seoul'
    }

    return timeZones[country]
}

export const AWS_S3 = {
    url: 'https://s3.ap-southeast-1.amazonaws.com',
    bucketName: 'private.ushop.unicity.com',
    albumName: 'ph_bir_file',
    region: 'ap-southeast-1',
    accessKeyId: 'AKIAIQDTHFTI7BUCE6TQ',
    secretAccessKey: '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',
    expire: 604800
}

export const TestAccounts = country => {
    const testAccounts = {
        thailand: ['108357166', '247639366', '247639066'],
        singapore: ['98767565', '98778365'],
        japan: ['101041581'],
        malaysia: ['97386460'],
        philippines: ['103562663'],
        vietnam: ['421035784'],
        indonesia: ['95605462'],
        uae: ['108357166'],
        taiwan: ['108357166'],
        hongkong: ['108357166'],
        cambodia: ['108357166'],
        australia: ['108357166'],
        laos: ['108357166'],
        myanmar: ['108357166'],
        newzealand: ['108357166'],
        taiwan: ['108357166']
    }
    return testAccounts[country]
}

export const UnicityOfficeCoordinates = country => {
    const coordinates = {
        thailand: {
            addresses: [
                {
                    name: 'Unicity Marketing Thailand',
                    lat: 13.7602696,
                    lng: 100.566256
                },
                {
                    name: 'Unicity Kamphaeng Phet',
                    lat: 16.473645,
                    lng: 99.5376522
                },
                {
                    name: 'Unicity Khonkaen K.Phawadon',
                    lat: 16.4042569,
                    lng: 102.8140456
                },
                {
                    name: 'Unicity Chanthaburi',
                    lat: 12.6008538,
                    lng: 102.1118826
                },
                {
                    name: 'Unicity Chachoengsao',
                    lat: 13.6679164,
                    lng: 101.053259
                },
                {
                    name: 'Unicity Chiang Mai',
                    lat: 18.7713533,
                    lng: 98.9515575
                },
                {
                    name: 'Unicity Nakornpathom',
                    lat: 13.8176069,
                    lng: 100.0820616
                },
                {
                    name: 'Unicity Nakhon Ratchasrima',
                    lat: 14.962536,
                    lng: 102.066707
                },
                {
                    name: 'Unicity Nakorn Si Thammarat',
                    lat: 8.4208162,
                    lng: 99.9737678
                },
                {
                    name: 'Unicity Nakornsawan K.Peerawat',
                    lat: 15.699661,
                    lng: 100.1184464
                },
                { name: 'Unicity Buriram', lat: 14.9999625, lng: 103.1146871 },
                {
                    name: 'Unicity Pathum Thani',
                    lat: 13.9865897,
                    lng: 100.6382867
                },
                {
                    name: 'Unicity Phanom Sarakham',
                    lat: 13.7337893,
                    lng: 101.3343967
                },
                { name: 'Unicity Phayao', lat: 19.1699719, lng: 99.8958582 },
                { name: 'Unicity Pattaya', lat: 12.9497983, lng: 100.899125 },
                {
                    name: 'Unicity Phitsanulok',
                    lat: 16.8198576,
                    lng: 100.2825887
                },
                {
                    name: 'Unicity DSC Palladium',
                    lat: 13.7501787,
                    lng: 100.5415377
                },
                {
                    name: 'Unicity Pata Pinklao',
                    lat: 13.771853,
                    lng: 100.4847221
                },
                {
                    name: 'Unicity Petchaburi',
                    lat: 13.0880604,
                    lng: 99.9567255
                },
                { name: 'Unicity Phuket', lat: 7.8764518, lng: 98.3858108 },
                { name: 'Unicity Roi Et', lat: 16.0570594, lng: 103.6421492 },
                { name: 'Unicity Ranong', lat: 9.9620277, lng: 98.6268586 },
                { name: 'Unicity Rayong', lat: 12.6879224, lng: 101.2438494 },
                {
                    name: 'Unicity DSC Rattanathibet',
                    lat: 13.864948,
                    lng: 100.4982808
                },
                { name: 'Unicity Lopburi', lat: 14.7870321, lng: 100.6350391 },
                { name: 'Unicity Ratchaburi', lat: 13.5358507, lng: 99.814524 },
                {
                    name: 'Unicity Lampang ศูนย์ยูนิซิตี้ลำปาง',
                    lat: 18.2841254,
                    lng: 99.4759512
                },
                { name: 'Unicity Sriracha', lat: 13.1436126, lng: 100.9144248 },
                {
                    name: 'Unicity Sakonnakorn',
                    lat: 17.160641,
                    lng: 104.1453963
                },
                { name: 'Unicity Songkhla', lat: 7.1853222, lng: 100.5958492 },
                {
                    name: 'Unicity Samut Prakan',
                    lat: 13.6707647,
                    lng: 100.625113
                },
                {
                    name: 'Unicity Samut Sakhon',
                    lat: 13.555776,
                    lng: 100.2751339
                },
                { name: 'Unicity Sukhothai', lat: 17.0114825, lng: 99.8403489 },
                {
                    name: 'Unicity Suphanburi',
                    lat: 14.4720356,
                    lng: 100.1294497
                },
                {
                    name: 'Unicity Surat Thani',
                    lat: 9.1179871,
                    lng: 99.3535307
                },
                {
                    name: 'Unicity Hat Yai Center',
                    lat: 7.0042921,
                    lng: 100.4882969
                },
                {
                    name: 'Unicity Ayutthaya',
                    lat: 14.3379246,
                    lng: 100.5990081
                },
                {
                    name: 'Unicity Udornthani K.Manassanan',
                    lat: 17.4075715,
                    lng: 102.8043143
                },
                {
                    name: 'Unicity Ubon Ratchathani',
                    lat: 15.2691858,
                    lng: 104.8410762
                }
            ]
        },
        singapore: {
            addresses: [
                { name: 'Unicity Singapore', lat: 1.264541, lng: 103.8190642 }
            ]
        },
        japan: {
            addresses: [
                { name: 'Unicity, Inc.', lat: 35.620695, lng: 139.72876 }
            ]
        },
        malaysia: {
            addresses: [
                {
                    name: 'Unicity Network Philippines, Inc.',
                    lat: 14.5844682,
                    lng: 121.0602499
                }
            ]
        },
        philippines: {
            addresses: [
                {
                    name: 'Unicity Network Philippines, Inc.',
                    lat: 14.5844682,
                    lng: 121.0602499
                },
                {
                    name: 'Unicity Philippines BGC',
                    lat: 14.5346401,
                    lng: 121.0495513
                }
            ]
        },
        vietnam: {
            addresses: [
                {
                    name: 'Unicity Marketing Vietnam, Ho Chi Minh',
                    lat: 10.8008128,
                    lng: 106.6507452
                },
                {
                    name: 'Unicity Vietnam, Hanoi',
                    lat: 21.0304966,
                    lng: 105.7848108
                }
            ]
        },
        indonesia: {
            addresses: [
                {
                    name: 'PT. Unicity Indonesia, Jakarta',
                    lat: -6.2407264,
                    lng: 106.8410837
                }
            ]
        },
        uae: {
            addresses: [
                {
                    name: 'Unicity Dubai, UAE',
                    lat: 25.1872085,
                    lng: 55.2668396
                },
                {
                    name: 'Unicity International General Trading L.L.C',
                    lat: 25.1875,
                    lng: 55.2668981
                }
            ]
        },
        taiwan: {
            addresses: [
                {
                    name: 'Unicity International Co., Ltd. Taipei',
                    lat: 25.0430698,
                    lng: 121.5658188
                }
            ]
        },
        hongkong: {
            addresses: [
                {
                    name: 'Unicity Marketing (HK) Co. Limited',
                    lat: 22.2778767,
                    lng: 114.1832377
                }
            ]
        },
        cambodia: {
            addresses: [
                {
                    name: 'Unicity, Phnom Penh',
                    lat: 11.5439105,
                    lng: 104.9379746
                }
            ]
        }
    }
    return coordinates[country]
}

export const exceptCountry = countryCode => {
    let country = {
        KH: true,
        LA: true,
        MM: true
    }
    return country[countryCode]
}

export const allProvincesList = countryCode => {
    let provinces = {
        PH: [
            { name: 'Abra', value: 'Abra' },
            { name: 'Agusan del Norte', value: 'Agusan del Norte' },
            { name: 'Agusan del Sur', value: 'Agusan del Sur' },
            { name: 'Aklan', value: 'Aklan' },
            { name: 'Albay', value: 'Albay' },
            { name: 'Antique', value: 'Antique' },
            { name: 'Apayao', value: 'Apayao' },
            { name: 'Aurora', value: 'Aurora' },
            { name: 'Basilan', value: 'Basilan' },
            { name: 'Bataan', value: 'Bataan' },
            { name: 'Batanes', value: 'Batanes' },
            { name: 'Batangas', value: 'Batangas' },
            { name: 'Benguet', value: 'Benguet' },
            { name: 'Biliran', value: 'Biliran' },
            { name: 'Bohol', value: 'Bohol' },
            { name: 'Bukidnon', value: 'Bukidnon' },
            { name: 'Bulacan', value: 'Bulacan' },
            { name: 'Cagayan De Oro', value: 'Cagayan De Oro' },
            { name: 'Cagayan', value: 'Cagayan' },
            { name: 'Caloocan', value: 'Caloocan' },
            { name: 'Camarines Norte', value: 'Camarines Norte' },
            { name: 'Camarines Sur', value: 'Camarines Sur' },
            { name: 'Camiguin', value: 'Camiguin' },
            { name: 'Capiz', value: 'Capiz' },
            { name: 'Catanduanes', value: 'Catanduanes' },
            { name: 'Cavite', value: 'Cavite' },
            { name: 'Cebu', value: 'Cebu' },
            { name: 'Compostela Valley', value: 'Compostela Valley' },
            { name: 'Cotabato', value: 'Cotabato' },
            { name: 'Davao Occidental', value: 'Davao Occidental' },
            { name: 'Davao Oriental', value: 'Davao Oriental' },
            { name: 'Davao del Norte', value: 'Davao del Norte' },
            { name: 'Davao del Sur', value: 'Davao del Sur' },
            { name: 'Dinagat Islands', value: 'Dinagat Islands' },
            { name: 'Eastern Samar', value: 'Eastern Samar' },
            { name: 'Guimaras', value: 'Guimaras' },
            { name: 'Ifugao', value: 'Ifugao' },
            { name: 'Ilocos Norte', value: 'Ilocos Norte' },
            { name: 'Ilocos Sur', value: 'Ilocos Sur' },
            { name: 'Iloilo', value: 'Iloilo' },
            { name: 'Isabela', value: 'Isabela' },
            { name: 'Kalinga', value: 'Kalinga' },
            { name: 'La Union', value: 'La Union' },
            { name: 'Laguna', value: 'Laguna' },
            { name: 'Lanao del Norte', value: 'Lanao del Norte' },
            { name: 'Lanao del Sur', value: 'Lanao del Sur' },
            { name: 'Las Pinas', value: 'Las Pinas' },
            { name: 'Leyte', value: 'Leyte' },
            { name: 'Maguindanao', value: 'Maguindanao' },
            { name: 'Makati', value: 'Makati' },
            { name: 'Malabon', value: 'Malabon' },
            { name: 'Mandaluyong', value: 'Mandaluyong' },
            { name: 'Manila', value: 'Manila' },
            { name: 'Marikina', value: 'Marikina' },
            { name: 'Marinduque', value: 'Marinduque' },
            { name: 'Masbate', value: 'Masbate' },
            { name: 'Misamis Occidental', value: 'Misamis Occidental' },
            { name: 'Misamis Oriental', value: 'Misamis Oriental' },
            { name: 'Mountain Province', value: 'Mountain Province' },
            { name: 'Muntinlupa', value: 'Muntinlupa' },
            { name: 'Negros Occidental', value: 'Negros Occidental' },
            { name: 'Negros Oriental', value: 'Negros Oriental' },
            { name: 'Northern Samar', value: 'Northern Samar' },
            { name: 'Novaliches', value: 'Novaliches' },
            { name: 'Nueva Ecija', value: 'Nueva Ecija' },
            { name: 'Nueva Vizcaya', value: 'Nueva Vizcaya' },
            { name: 'Occidental Mindoro', value: 'Occidental Mindoro' },
            { name: 'Oriental Mindoro', value: 'Oriental Mindoro' },
            { name: 'Palawan', value: 'Palawan' },
            { name: 'Pampanga', value: 'Pampanga' },
            { name: 'Pangasinan', value: 'Pangasinan' },
            { name: 'Paranaque', value: 'Paranaque' },
            { name: 'Pasay', value: 'Pasay' },
            { name: 'Pasig', value: 'Pasig' },
            { name: 'Quezon City', value: 'Quezon City' },
            { name: 'Quezon', value: 'Quezon' },
            { name: 'Quirino', value: 'Quirino' },
            { name: 'Rizal', value: 'Rizal' },
            { name: 'Romblon', value: 'Romblon' },
            { name: 'Samar', value: 'Samar' },
            { name: 'San Juan', value: 'San Juan' },
            { name: 'Sarangani', value: 'Sarangani' },
            { name: 'Siquijor', value: 'Siquijor' },
            { name: 'Sorsogon', value: 'Sorsogon' },
            { name: 'South Cotabato', value: 'South Cotabato' },
            { name: 'Southern Leyte', value: 'Southern Leyte' },
            { name: 'Sultan Kudarat', value: 'Sultan Kudarat' },
            { name: 'Sulu', value: 'Sulu' },
            { name: 'Surigao del Norte', value: 'Surigao del Norte' },
            { name: 'Surigao del Sur', value: 'Surigao del Sur' },
            { name: 'Taguig', value: 'Taguig' },
            { name: 'Tarlac', value: 'Tarlac' },
            { name: 'Tawi-Tawi', value: 'Tawi-Tawi' },
            { name: 'Valenzuela', value: 'Valenzuela' },
            { name: 'Zambales', value: 'Zambales' },
            { name: 'Zamboanga Sibugay', value: 'Zamboanga Sibugay' },
            { name: 'Zamboanga del Norte', value: 'Zamboanga del Norte' },
            { name: 'Zamboanga del Sur', value: 'Zamboanga del Sur' }
        ]
    }
    return provinces[countryCode]
}

export const bestSelling = countryCode => {
    let countries = {
        SG: true,
        MY: true,
        TH: true,
        PH: true,
        JP: true,
        EN: true,
        MY: true,
        VN: true,
        CN: true,
        AE: true,
        ID: true,
        AU: true,
        NZ: true,
        TW: true
    }

    return countries[countryCode]
}

export const showInputRange = status => {
    if (status.id !== undefined) {
        switch (status.type) {
            case 'Customer': {
                return false
            }
            case 'PreferredCustomer': {
                return false
            }
            default: {
                return true
            }
        }
    } else {
        return false
    }
}

export const currencyBehindPrice = countryCode => {
    let countries = {
        SG: 'right',
        MY: 'right',
        TH: 'right',
        PH: 'right',
        JP: 'right',
        EN: 'right',
        MY: 'right',
        VN: 'right',
        CN: 'right',
        AE: 'right',
        ID: 'right',
        AU: 'right',
        NZ: 'right',
        TW: 'right',
        HK: 'right',
        KR: 'right'
    }

    const result = countries[countryCode]
    if (isNothing(result)) return 'right'
    else return result
}

export const currencyPositionOrderHistory = countryCode => {
    let countries = {
        SG: 'left',
        MY: 'left',
        TH: 'left',
        PH: 'left',
        JP: 'left',
        EN: 'left',
        MY: 'left',
        VN: 'left',
        CN: 'left',
        AE: 'left',
        ID: 'left',
        AU: 'left',
        NZ: 'left',
        TW: 'left',
        KR: 'left'
    }

    return countries[countryCode]
}

// Card Limit config
export function cartLimitConfig(country) {
    const list = {
        bahrain: {
            qty: 0,
            total: 0
        },
        cambodia: {
            qty: 0,
            total: 0
        },
        hongkong: {
            qty: 0,
            total: 0
        },
        india: {
            qty: 0,
            total: 0
        },
        indonesia: {
            qty: 0,
            total: 0
        },
        japan: {
            qty: 0,
            total: 0
        },
        laos: {
            qty: 0,
            total: 0
        },
        malaysia: {
            qty: 0,
            total: 0
        },
        myanmar: {
            qty: 0,
            total: 0
        },
        philippines: {
            qty: 0,
            total: 0
        },
        singapore: {
            qty: 0,
            total: 0
        },
        taiwan: {
            qty: 0,
            total: 0
        },
        thailand: {
            qty: 0,
            total: 0
        },
        vietnam: {
            qty: 0,
            total: 0
        },
        uae: {
            qty: 0,
            total: 0
        },
        united_states: {
            qty: 0,
            total: 0
        },
        korea: {
            qty: 0,
            total: 0
        }
    }
    return list[country]
}

export const BATestList = () => {
    return [
        '2667166',
        '3011266',
        '2965766',
        '96788066',
        '8444566',
        '122339366',
        '3121366',
        '5710366',
        '96250466',
        '3341366',
        '100280466',
        '105640066',
        '5801766',
        '2808766',
        '151440966',
        '108357166'
    ]
}

// Payment Gateways configs
export const paymentGatewayConfig = country => {
    const list = {
        indonesia: 'midtrans',
        australia: paymentGateway.name.ocbcExpressGateway,
        newzealand: paymentGateway.name.hydraGateway,
        philippines: paymentGateway.name.cbsGateway,
        taiwan: paymentGateway.name.ecPayGateway,
        hongkong: paymentGateway.name.cityLineGateway,
        malaysia: paymentGateway.name.cyberSourceGateway
    }
    return isNothing(list[country])
        ? paymentGateway.name.ocbcGateway
        : list[country]
}

// Share a Cart configs
export const shareACartConfig = country => {
    const list = {
        australia: isProduction() ? false : true,
        newzealand: isProduction() ? false : true,
        singapore: true,
        thailand: true,
        philippines: true,
        hongkong: true
    }
    return isNothing(list[country]) ? false : list[country]
}

// PostBack shortcut buttons configs
export const postBackShortcutConfig = country => {
    const list = {
        singapore: true
    }
    return isNothing(list[country]) ? false : list[country]
}

// Store country configs that transfer from Express version
export const transferFromExpressConfig = country => {
    const list = {
        australia: true,
        newzealand: true
    }
    return isNothing(list[country]) ? false : list[country]
}

// Country Code configs that used with other API
export const APICountryCodeConfig = country => {
    const list = {
        australia: 'XAU',
        newzealand: 'XNZ'
    }
    return isNothing(list[country]) ? Country.getCode2() : list[country]
}

export const APICountryCode3Config = country => {
    const list = {}
    return isNothing(list[country]) ? Country.getCode3() : list[country]
}

export const marketCountryCodeConfig = country => {
    const list = {
        australia: 'SG',
        newzealand: 'SG'
    }
    return isNothing(list[country]) ? Country.getCode2() : list[country]
}

export const productCountryCode3Config = country => {
    const list = {}
    return isNothing(list[country]) ? Country.getCode3() : list[country]
}

export const enrollMaritalStatusConfig = country => {
    const list = {
        thailand: true,
        japan: true,
        indonesia: true,
        australia: true,
        newzealand: true
    }
    return isNothing(list[country]) ? false : list[country]
}

export const showArBalance = (country, status, component) => {
    let allowedCoutries = ['SG']
    let result = inArray(country, allowedCoutries)
    let allowedUser = false

    if (component === 'order_history') {
        result = true
    }

    if (status !== undefined) {
        switch (status.type) {
            case 'Customer': {
                allowedUser = false
                break
            }
            case 'PreferredCustomer': {
                allowedUser = false
                break
            }
            case 'Employee': {
                allowedUser = true
                break
            }
            default: {
                allowedUser = false
                break
            }
        }
    } else {
        return false
    }

    if (result && allowedUser) {
        return true
    }
}

export const isAllowBalance = () => {
    if (isAnonymous()) return false

    const country = Country.getCode2()
    const userType = getCustomerType()

    if (storeBalance.isAllow === false) return false
    if (isNothing(country)) return false
    if (isNothing(userType)) return false

    // cash coupon currency should matched with current country currency
    if (
        storeBalance.currency !==
        staticFooter.footerGeneral.currency_code.english
    ) {
        return false
    }

    let allowedUser = false

    let type = ''
    if (isString(userType)) {
        type = userType
    } else {
        type = get(userType, 'type', '')
    }

    /* const config = {
        Thailand: {
            ['Associate']: true,
            ['Customer']: false,
            ['PreferredCustomer']: false,
            ['Employee']: true,
            ['Performance Labs']: true
        },
        Signapore: {
            ['Associate']: false,
            ['Customer']: false,
            ['PreferredCustomer']: false,
            ['Employee']: false,
            ['Performance Labs']: true
        }
    } */

    switch (type) {
        case 'Associate':
            allowedUser = Country.isThailand()
            break
        case 'Customer':
            allowedUser = false
            break
        case 'PreferredCustomer':
            allowedUser = false
            break
        case 'Employee':
            allowedUser = true
            break
        case 'Performance Labs':
            allowedUser = true
            break
        default:
            allowedUser = false
            break
    }

    return allowedUser
}

export const useNewProductCatalog = true
export const useFacebookLogin = true
export const countriesUsingFacebookLogin = countryCode => {
    return inArray(countryCode, ['TH'])
}
export const SESSION_TIMEOUT = 12 * 60 * 60 * 1000 // 12hrs
export const SESSION_TIMESTAMP_KEY = 'session-timestamp'
