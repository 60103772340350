import { language } from '@Language';
import { checkOutStore } from '@Stores/MainStore';
import { isArray, isNothing, someOf } from '@Utils/Utils';
import { get, has, keys, first, omit, isArrayLikeObject, startsWith } from 'lodash';
import { language as l } from '@Language';
import { isObservableArray } from 'mobx';
import { throatArray, throatBoolean, throatObject } from './ConfigsHeader';
import * as K from './Keywords';
class classShippingMethods {

    WarehouseEnabled = {
        /* // an example for warehouse case
        [K.Indonesia]: true */
    }

    WarehouseAcceptance = {
        /* // an example for warehouse case
        [K.Indonesia]: ['jakarta', 'surabaya'] */
    }

    ShippingAcceptance = {
        [K.Korea]: ['delivery', 'pickUp'],
        /* // an example for pickup with special case
        [K.Philippines]: ['delivery', 'pickUp.ph_taguig'],
        /* // an example for warehouse case
        [K.Indonesia]: {
            jakarta: ['delivery', 'pickUp', 'motorDelivery.id_jakarta'],
            surabaya: ['delivery', 'pickUp', 'motorDelivery.id_surabaya']
        }, */
    }

    ShippingDeclaration = {
        [K.Delivery]: {
            [K.Default]: {
                value: '2',
                dictionary: 'od_shipping_delivery'
            }
        },
        [K.PickUp]: {
            [K.Default]: {
                value: '3',
                dictionary: 'od_shipping_pickup'
            },
            /* // an example for special case
            ph_taguig: {
                value: '32',
                dictionary: 'u_ph_od_shipping_pickup_taguig'
            } */
        }
    }

    AramexDeclaration = {
        address: {
            /* // an example for address
            [K.Singapore]: {
                address1: 'Aramax (AIC Dept.) 3 Changi South Street 1, #01-01',
                address2: 'Santa United International  Bldg.',
                city: 'Singapore',
                state: '',
                zip: '486795',
                country: 'SG',
                mobilePhone: '6565430300'
            } */
        }
    }

    PickupDeclaration = {
        address: {
            [K.Korea]: {
                dictionary: 'od_pickup_address',
                value: {
                    KR: {
                        fullName: 'Korea, Main Office',
                        address1: '154-1 서린동',
                        address2: '남제주군',
                        state: '',
                        city: '서울',
                        country: 'KR',
                        zip: '688823'
                    },
                    EN: {
                        fullName: 'Korea, Main Office',
                        address1: '154-1 Seorin-dong',
                        address2: 'Namjeju-gun',
                        state: '',
                        city: 'Seoul',
                        country: 'KR',
                        zip: '688823'
                    }
                }
            }
        }
    }

    ID = {
        /** ID = 2 */
        delivery: '2',
        /** ID = 3 */
        pickUp: '3',
        /** ID = 4 */
        aramex: '4',
        /** ID = 5 */
        pickUpHochiminh: '5',
        /** ID = 6 */
        motorDelivery: '6',
        /** ID = 7 */
        deliverySBY: '7',
        /** ID = 8 */
        pickUpSBY: '8',
        /** ID = 9 */
        motorDeliverySBY: '9',
        /** ID = 10 */
        pickUpTaguig: '32',
        /** ID = 501 */
        samedayDelivery: '501'
    }

    name = {
        delivery: 'delivery',
        pickUp: 'pickUp',
    }

    type = {
        delivery: 'delivery',
        pickUp: 'pickUp',
    }

    dictionary = {
        delivery: 'delivery',
        pickUp: 'pickup',
    }

    IDToName = {
        '2': 'delivery',
        '3': 'pickUp',
    }

    IDToType = {
        '2': 'delivery',
        '3': 'pickUp',
    }

    getShippingName(shippingID = '') {
        if (isNothing(shippingID)) shippingID = checkOutStore.shipping
        return this.IDToName[shippingID]
    }

    getShippingType(shippingID = '') {
        if (isNothing(shippingID)) shippingID = checkOutStore.shipping
        return this.IDToType[shippingID]
    }

    isAramex(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.aramex
    }

    isDelivery(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.delivery
    }

    isPickup(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.pickUp
    }

    isMotorDelivery(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.motorDelivery
    }

    isSamedayDelivery(shippingID = '') {
        return this.getShippingType(shippingID) === this.type.samedayDelivery
    }

    getWarehouseEnabled() {
        return throatBoolean(this.WarehouseEnabled)
    }

    getWarehouseAcceptance() { 
        return throatArray(this.WarehouseAcceptance) 
    }

    getShippingAcceptance() { 
        return throatArray(this.ShippingAcceptance) 
    }

    isAcceptedMethod() {
        let acceptance = this.getShippingAcceptance()
        if (isArray(acceptance) || isObservableArray(acceptance)) {
            acceptance = acceptance.map((item) => first(item.split('.')))
        } else {
            acceptance = get(acceptance, `[${checkOutStore.warehouse}]`, []).map((item) => first(item.split('.')))
        }
        return someOf(this.getShippingType(), acceptance)
    }

    getPickupAddress() {
        const addressData = throatObject(this.PickupDeclaration.address)
        const omitedData = omit(addressData, ['dictionary', 'value'])
        let dictionary, value
        if (keys(omitedData).length > 0) {
            dictionary = get(omitedData, `${checkOutStore.warehouse}.dictionary`)
            value = get(omitedData, `${checkOutStore.warehouse}.value`)
        } else {
            dictionary = get(addressData, 'dictionary')
            value = get(addressData, 'value')
        }
        if (has(value, 'EN') || has(value, 'en')) {
            value = language.listen(value)
        }
        return {
            dictionary,
            value
        }
    }

    getShippingDeclarationDictionary() {
        // get shipping methods list of current country
        let list = this.getShippingAcceptance()
        // if it not array like, assume as warehouse support type
        if (isArrayLikeObject(list) === false) {
            let legitWarehouse = checkOutStore.warehouse
            if (isNothing(legitWarehouse)) {
                // handle empty value on warehouse by set default to first one
                legitWarehouse = first(this.getWarehouseAcceptance())
                checkOutStore.warehouse = legitWarehouse
            }
            // convert list to legit warehouse data
            list = list[legitWarehouse]
        }

        const method = list.find(item => item.split('.')[0] === this.getShippingType())
        if (method) {
            let key = ''
            if (method.indexOf('.') === -1) {
                key = get(this.ShippingDeclaration, `${method}.default.dictionary`)
            } else {
                key = get(this.ShippingDeclaration, `${method}.dictionary`)
            }
            return key
        } else {
            return ''
        }
    }

}

const core = new classShippingMethods()

export { core as default }
export { core as ShippingMethods }