import storeGenealogy from "@Stores/StoreGenealogy"
import StoreAuth from "@Stores/User/StoreAuth"


// export const AddToBreadCrumb = (customer, page) => {
//
//     let breadcrumbs = sessionStorage.getItem(`${StoreAuth.id}-breadcrumbs`)
//     let breadcrumbsHistory = sessionStorage.getItem(`${StoreAuth.id}-breadcrumbsHistory`)
//
//     if (breadcrumbs) {
//
//         breadcrumbs = JSON.parse(breadcrumbs)
//         breadcrumbsHistory = JSON.parse(breadcrumbsHistory)
//     }  else {
//         breadcrumbs = []
//         breadcrumbsHistory = []
//
//     }
//
//     const isInBreadCrumbs = breadcrumbs && customer ? breadcrumbs.find(c => {
//         if (c) {
//             return c.id.unicity === customer.id.unicity
//         } else {
//             return false
//         }
//     }) : false
//
//
//
//
//     if (!isInBreadCrumbs) {
//
//         if (breadcrumbs.length === 10) {
//             breadcrumbs.pop()
//             breadcrumbsHistory.pop()
//         }
//
//         breadcrumbsHistory = []
//         breadcrumbs.unshift(customer)
//         breadcrumbs.map(v => {
//             breadcrumbsHistory.push(v.id.unicity)
//         })
//
//         setTimeout(() => {
//             storeGenealogy.breadcrumbs = breadcrumbs
//             storeGenealogy.breadcrumbsHistory = breadcrumbsHistory
//             sessionStorage.setItem(`${StoreAuth.id}-breadcrumbs`, JSON.stringify(breadcrumbs))
//             sessionStorage.setItem(`${StoreAuth.id}-breadcrumbsHistory`, JSON.stringify(breadcrumbsHistory))
//         }, 1000)
//
//     } else {
//
//
//         if(breadcrumbsHistory[0] !== undefined) {
//             if(breadcrumbsHistory[0] === null) {
//                 breadcrumbsHistory = []
//                 breadcrumbs.map(v => {
//                     breadcrumbsHistory.push(v.id.unicity)
//                 })
//             }
//         }
//
//         const index = breadcrumbsHistory.indexOf(customer.id.unicity)
//         if (index > -1) {
//
//             breadcrumbs.splice(index, 1)
//             breadcrumbs.unshift(customer)
//
//             breadcrumbsHistory = []
//             breadcrumbs.map(v => {
//                 breadcrumbsHistory.push(v.id.unicity)
//             })
//
//             setTimeout(() => {
//
//
//                 storeGenealogy.breadcrumbs = breadcrumbs
//                 storeGenealogy.breadcrumbsHistory = breadcrumbsHistory
//
//                 sessionStorage.setItem(`${StoreAuth.id}-breadcrumbs`, JSON.stringify(breadcrumbs))
//                 sessionStorage.setItem(`${StoreAuth.id}-breadcrumbsHistory`, JSON.stringify(breadcrumbsHistory))
//             }, 1000)
//         }
//
//     }
//
//
// }
export const AddToBreadCrumb = (customer, page) => {

    let breadcrumbs = sessionStorage.getItem(`${StoreAuth.id}-breadcrumbs`)
    let breadcrumbsHistory = sessionStorage.getItem(`${StoreAuth.id}-breadcrumbsHistory`)

    if (breadcrumbs) {
        
        breadcrumbs = JSON.parse(breadcrumbs)
        breadcrumbsHistory = JSON.parse(breadcrumbsHistory) 
    }  else {
        breadcrumbs = []
        breadcrumbsHistory = []

    }
 
    const isInBreadCrumbs = breadcrumbs && customer ? breadcrumbs.find(c => {
        if (c) {
            return c.id.unicity === customer.id.unicity
        } else {
            return false
        }
    }) : false

    


    if (!isInBreadCrumbs) {

        if (breadcrumbs.length === 10) {
            breadcrumbs.pop()
            breadcrumbsHistory.pop()
        }

        breadcrumbsHistory = []
        breadcrumbs.unshift(customer)
        breadcrumbs.map(v => {
            breadcrumbsHistory.push(v.id.unicity)
        })

        setTimeout(() => {
            storeGenealogy.breadcrumbs = breadcrumbs
            storeGenealogy.breadcrumbsHistory = breadcrumbsHistory
            sessionStorage.setItem(`${StoreAuth.id}-breadcrumbs`, JSON.stringify(breadcrumbs))
            sessionStorage.setItem(`${StoreAuth.id}-breadcrumbsHistory`, JSON.stringify(breadcrumbsHistory))
        }, 1000)

    } else {

        
        if(breadcrumbsHistory[0] !== undefined) {
            if(breadcrumbsHistory[0] === null) {
                breadcrumbsHistory = []
                breadcrumbs.map(v => {
                    breadcrumbsHistory.push(v.id.unicity)
                })
            }
        } 
        
        const index = breadcrumbsHistory.indexOf(customer.id.unicity)
        if (index > -1) {

            breadcrumbs.splice(index, 1)
            breadcrumbs.unshift(customer)

            breadcrumbsHistory = []
            breadcrumbs.map(v => {
                breadcrumbsHistory.push(v.id.unicity)
            })

            setTimeout(() => {

                
                storeGenealogy.breadcrumbs = breadcrumbs
                storeGenealogy.breadcrumbsHistory = breadcrumbsHistory

                sessionStorage.setItem(`${StoreAuth.id}-breadcrumbs`, JSON.stringify(breadcrumbs))
                sessionStorage.setItem(`${StoreAuth.id}-breadcrumbsHistory`, JSON.stringify(breadcrumbsHistory))
            }, 1000)
        }

    }


}