import React, { Fragment, useState, useEffect } from 'react';
import { useObserver, observer } from 'mobx-react';
import { store } from '@Stores/MainStore';
import { numeralFormat } from '@GlobalHelpers';
import { defaults, someOf } from '@Utils/Utils';

/**
 * @param {object} props
 * @param {number} props.price
 * @param {string} props.currency
 * @param {boolean} props.inline
 * @param {boolean} props.separateLine
 * @param {'none'|'left'|'right'} props.currencyPosition
 */
const PriceWithCurrency = props => { 

    const [lineType, setLineType] = useState('')

    useEffect(() => {
        setLineType(props.inline ? 'inline' : props.separateLine ? 'separate' : '')
    }, [props.inline, props.separateLine])

    const noCurrency = (price) => {
        if (props.separateLine === true) {
            return (<p style={{textAlign:'center'}} className="no-margin cart-text">{numeralFormat(price)}</p>)
        } else {
            return <Fragment>{numeralFormat(price)}</Fragment>
        }
    }

    return useObserver(() => 
        <>
        {props.currencyPosition ? 
            <>
            {someOf(props.currencyPosition, ['left', 'right']) ?
                <>
                {props.currencyPosition === 'left' && 
                    <CurrencyLeft price={props.price} currency={props.currency} lineType={lineType}/>
                }
                {props.currencyPosition === 'right' && 
                    <CurrencyRight price={props.price} currency={props.currency} lineType={lineType}/>
                }
                </>
                :
                noCurrency(props.price)
            }
            </>
            : 
            noCurrency(props.price)
        }
        </>
    )
}

/**
 * @param {object} props
 * @param {'inline'|'separate'} props.lineType
 */
const CurrencyLeft = observer(({ price, currency, lineType = 'inline' }) => {
    if (lineType === 'separate') {
        return (
            <Fragment>
                <p style={{textAlign:'center'}} className="no-margin cart-text">{currency}</p> 
                <p style={{textAlign:'center'}} className="no-margin cart-text">{numeralFormat(price)}</p>
            </Fragment>
        )
    } else if (lineType === 'inline') {
        return (<p style={{textAlign:'center'}} className="no-margin cart-text">{currency}&nbsp;{numeralFormat(price)}</p>)
    } else {
        return <Fragment>{currency}&nbsp;{numeralFormat(price)}</Fragment>
    }
})

/**
 * @param {object} props
 * @param {'inline'|'separate'} props.lineType
 */
const CurrencyRight = observer(({ price, currency, lineType = 'inline' }) => {
    if (lineType === 'separate') {
        return (
            <Fragment>
                <p style={{textAlign:'center'}} className="no-margin cart-text text-xs">{numeralFormat(price)}</p>
                <p style={{textAlign:'center'}} className="no-margin cart-text text-xs">{currency}</p> 
            </Fragment>
        )
    } else if (lineType === 'inline') {
        return (
            <Fragment>
                <p style={{textAlign:'center'}} className="no-margin cart-text">{numeralFormat(price)}&nbsp;{currency}</p> 
            </Fragment>
        )
    } else {
        return (<Fragment>{numeralFormat(price)} {currency}</Fragment>)
    }
})

export default PriceWithCurrency