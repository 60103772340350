import React, { useEffect } from 'react'
import { reaction } from 'mobx'
import { makeCustomersInternational } from '../services/Network'
import {isCountryVietnam, getNativeName, isMobile, dictionary, getFullRankName, getCountryCode3} from './GlobalHelpers'
import { assignCustomer, clearCartStorage } from './utils/Customer'
import { resetReferralStore } from './EnrollHelper'
import { ACL, AllowList, Menu, appConfig } from './config'

import { loge, logn } from '@Utils/PikaLog'
import { StorageExtend } from '@Utils/StorageManager'
import { isSomething, isNothing, someOf } from '@Utils/Utils'
import { getQueryString } from '@Configs/ConfigsHeader'
import { Country } from '@Configs/Country'

import { store, checkOutStore } from '@Stores/MainStore'
import StoreAuth from '@Stores/User/StoreAuth'
import StoreMenu from '@Stores/StoreMenu'
import SessionAuth from '@Stores/User/SessionAuth'
import StoreProfile from '@Stores/StoreProfile'

import get from 'lodash/get'
import axios from 'axios'
import Raven from '@Raven'
import { updateBalance } from '@Services/APIs/APIBalance'
import { lowerCase } from '@Utils/String'
import { storeAPI } from '@Stores/StoreAPI'
import StoreCountry from '@Stores/StoreCountry'
import { storeNotification } from '@Stores/StoreNotification'
import KakaoService from '@Services/Kakao'
import displayService from '@Services/v2/unishop/display'
import { storeFilter } from '@Stores/StoreFilter'
import {storeBowser} from "@Stores/StoreBowser";
import Modal2, {Alert as Alert2, Confirm} from '@Components/v2/modal'
import {language} from "@Language";
import {Else, If, Then} from "react-if";
import {GetGenealogyData} from "@Components/dashboard/APIs";

const { Code2 } = Country
const optionsReaction = { fireImmediately: true }

/** Applied the authorize reactions to component.
 * The process begin after acknowledge the login type.
 */
export const useAuthorization = () => {
    /** Query string params for auto-login. */
    const { autoLogin: qsToken } = getQueryString()

    useEffect(() => {
        /** Watch login type to decide the login process after acknowlege.
         * Set auto-login token or restore user. */
        reaction(
            () => StoreAuth.loginType,
            (type, pType, rType) => {
                if (type && isNothing(pType)) {
                    if (StoreAuth.isAutoLogin) {
                        // start a request for required data of user
                        requestCustomer(qsToken)
                            .then(response => {
                                if (get(response, 'data.payload', false)) {
                                    const { baId, token } = get(
                                        response,
                                        'data.payload'
                                    )
                                    processAutoLogin(
                                        baId,
                                        token,
                                        StoreCountry.Country3()
                                    )
                                } else {
                                    failed(response)
                                }
                            })
                            .catch(error => {
                                failed(error)
                            })
                    } else {
                        // try to restore the current user
                        requestSessionRestore()
                    }
                    // destroy this reaction
                    rType.dispose()
                }
            },
            optionsReaction
        )

        // Decide type of login
        if (isSomething(qsToken)) {
            // The query string for auto-login is exist
            StoreAuth.setLoginToAuto()
        } else {
            // No auto-login
            StoreAuth.setLoginToNormal()
        }
    }, [])

    const failed = reason => {
        console.error('Auto-login colud not be done:', reason)
        StoreAuth.setAutoLoginStatus('failed')
        StoreAuth.isInitilized = true
    }

    const requestCustomer = async autoLoginToken =>
        await axios(
            `https://member-calls2-kr.unicity.com/remoteStorage/broker/${autoLoginToken}`
        )

    const processAutoLogin = async (
        customerID,
        customerToken,
        countryCode3
    ) => {
        logn('🔐 🤖 Auto-login has been detect.')

        await Raven.getMenuWithLogin(customerID, countryCode3, customerToken)
            .then(response => {
                sessionStorage.setItem(
                    'customerData',
                    JSON.stringify(response.onself.ushop.profile)
                )
                sessionStorage.setItem(
                    'customerToken',
                    response.checkToken.token
                )
                sessionStorage.setItem(
                    'customerHref',
                    response.onself.ushop.profile.href
                )
                sessionStorage.setItem('user-token', response.checkToken.token)
                sessionStorage.setItem(
                    'user-href',
                    response.onself.ushop.profile.href
                )
                sessionStorage.setItem(
                    'onself.ushop-response',
                    JSON.stringify(response.onself.ushop)
                )
                sessionStorage.setItem('menu', JSON.stringify(response.menu))

                response.customer = {}
                response.customer.href = response.onself.ushop.profile.href
                response.token = response.checkToken.token

                const dataNotification = get(response, 'popup[0]')
                if (isSomething(dataNotification)) {
                    storeNotification.content = dataNotification
                    storeNotification.isLoaded = true
                }

                processCustomerData(response, () => {
                    const customerID = get(
                        response.onself.ushop,
                        'profile.id.unicity',
                        ''
                    )
                    const sizes = get(
                        response.onself.ushop.profile,
                        'profilePicture.sizes',
                        []
                    )
                    const avatar = get(
                        sizes.find(item => item.size === '500x500'),
                        'media',
                        ''
                    )

                    if (isSomething(customerID)) {
                        StorageExtend.setToCountry(
                            {
                                avatar: avatar,
                                id: customerID,
                                name_english: getNativeName(
                                    get(response.onself.ushop, 'profile.humanName'),
                                    'fullName'
                                ),
                                name_native: getNativeName(
                                    get(response.onself.ushop, 'profile.humanName'),
                                    'nativeName'
                                ),
                                timestamp: new Date().getTime()
                            },
                            `login-recovery.${customerID}`
                        )
                    }

                    Object.keys(sessionStorage).map((v, k) => {
                        if (/^menuGroup+?/.test(v)) {
                            sessionStorage.removeItem(v)
                        }
                    })

                    StoreAuth.setAutoLoginStatus('success')
                    StoreAuth.InitUser(response)
                })
            })
            .catch(error => {
                failed(error)
            })
    }
}

const adminToken = async() => {
    const url = 'https://member-kr2.unicity.com/unifoapi/backend/crud/gettoken';
    const token = await axios.get(url)
    StoreAuth.adminToken = token.data.token;
}

const disaplyMoSetting = async() => {
    const searchParams = {
        gb: 'load',
    }

    const loadResult = await displayService.query(searchParams)

    if (loadResult && loadResult.data && loadResult.data.length > 0) {
        if(loadResult.data[0].cpv === 'true') {
            storeFilter.setDisplayCpv(true)
        } else {
            storeFilter.setDisplayCpv(false)
        }

        if(loadResult.data[0].list === 'true') {
            storeFilter.setDisplayList(true)
        } else {
            storeFilter.setDisplayList(false)
        }
    } else {

        storeFilter.setDisplayCpv(false)
        storeFilter.setDisplayList(true)

        await displayService.query({
            gb: 'insert',
            params: {
                cpv: false,
                list: true
            }
        })
    }
}

const updateApiLoad = () => {
    storeAPI.setApiLoaded('menu', true)
    storeAPI.MainApiStartLoad.menu = false
}

const requestSessionRestore = () => {
    SessionAuth.ShouldRestore()
        .then(() => {
            console.log(
                '---------------------------- requestSessionRestore ----------------------------'
            )
            storeAPI.MainApiStartLoad.menu = true
            StoreAuth.Restore(SessionAuth.Current())
                .then(response => {
                    logn('✅ 🔐 Successfully restore session:\n>')
                    if (
                        get(response, 'forceResetPassword.isRequred', false) ===
                        true
                    ) {
                        SessionAuth.SetNeedResetPassword(true)
                        storeAPI.setApiLoaded('menu', true)
                        storeAPI.MainApiStartLoad.menu = false
                    } else {
                        StoreProfile.setProfileData(
                            get(response, 'onself.ushop.profile')
                        )
                        StoreProfile.setCommission(
                            get(response, 'onself.ushop.commission')
                        )
                        StoreProfile.setLsb(get(response, 'onself.ushop.lsb'))

                        const allowed = get(
                            response,
                            'onself.ushop.profile.acl.allowed'
                        )

                        StoreAuth.ACLFile = 'AclFull'

                        StoreMenu.setMenu(response.menu)
                        StoreMenu.setQuotes(response.quotes)
                        storeAPI.setApiLoaded('menu', true)
                        storeAPI.MainApiStartLoad.menu = false
                        // * Update BALANCE features (CashCoupon/ARBalance)
                        updateBalance()

                        adminToken()

                        disaplyMoSetting()
                    }
                })
                .catch(error => {
                    loge('❌ 🔐 Failed to restore session:', error)
                    StoreAuth.isInitilized = true
                    updateApiLoad()
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href = './menu'
                })
        })
        .catch(e => {
            if (e.message === 'no-token') {
                loge('❌ 🔐 Failed to restore session:', e)
                if (Country.path) {
                    logn('✅ 🔐 Anonymous user detected, no need to authorize.')
                    StoreAuth.ACLFile = 'AclPublic'
                    storeAPI.MainApiStartLoad.menu = true
                    Raven.getMenuPublic()
                        .then(response => {
                            StoreMenu.setMenu(response.menu)
                            StoreMenu.setQuotes(response.quotes)
                            storeNotification.setRawContent(response.popup)
                            StoreAuth.isInitilized = true
                            updateApiLoad()
                        })
                        .catch(error => {
                            console.log('error getMenuPublic:', error)
                            StoreAuth.isInitilized = true
                            updateApiLoad()
                        })
                } else {
                    logn('✅ 🔐 The authorization is no need.')
                }
            } else {
                localStorage.clear()
                sessionStorage.clear()
                console.log('requestSessionRestore/catch>>>>>>>>>>>>>>>>>')
                window.location.href = './menu'
            }
        })
}

// const requestSessionRestore = () => {
//     SessionAuth.ShouldRestore()
//         .then(() => {
//             console.log(
//                 '---------------------------- requestSessionRestore ----------------------------'
//             )
//             storeAPI.MainApiStartLoad.menu = true
//             StoreAuth.Restore(SessionAuth.Current())
//                 .then(response => {
//                     logn('✅ 🔐 Successfully restore session:\n>')
//                     StoreProfile.setProfileData(get(response, 'onself.ushop.profile'))
//                     StoreProfile.setCommission(
//                         get(response, 'onself.ushop.commission')
//                     )
//                     StoreProfile.setLsb(get(response, 'onself.ushop.lsb'))

//                     const allowed = get(response, 'onself.ushop.profile.acl.allowed')

//                     StoreAuth.ACLFile = 'AclFull'

//                     StoreMenu.setMenu(response.menu)

//                     // * Update BALANCE features (CashCoupon/ARBalance)
//                     updateBalance()

//                     adminToken()
//                 })
//                 .catch(error => {
//                     loge('❌ 🔐 Failed to restore session:', error)
//                     logout()
//                 })
//         })
//         .catch(() => {
//             StoreAuth.ACLFile = 'AclPublic'
//             Raven.getMenuPublic()
//                 .then(response => {
//                     StoreMenu.setMenu(response.menu)
//                     const dataNotification = get(response, 'popup[0]')
//                     if (isSomething(dataNotification)) {
//                         storeNotification.content = dataNotification
//                         storeNotification.isLoaded = true
//                     }
//                 })
//                 .catch(error => {
//                     console.log('error getMenuPublic:', error)
//                 })
//                 .finally(() => {
//                     StoreAuth.isInitilized = true
//                 })
//         })
// }

// --------

const recognizeCustomer = (data, href, token) => {
    localStorage.removeItem('referral')
    assignCustomer(data, href, token)
}

const getAcl = (type, status) => {
    let acl = null
    let menu = []
    Object.keys(ACL).map((v, k) => {
        if (ACL[v].api.type === type && ACL[v].api.status === status) {
            console.log(ACL[v])
            if (ACL[v].allowed === false) {
                return false
            }

            acl = ACL[v]
            acl.status = v
            acl.allowList = AllowList[ACL[v].allowed]

            if (acl.allowList === true) {
                acl.mainMenu = Menu()
            } else {
                let menu = []
                let tempMenu = Menu()

                tempMenu.map((b, i) => {
                    if (acl.allowList.sideBar.indexOf(b.key) >= 0) {
                        menu.push(b)
                    }
                })
                acl.mainMenu = menu
            }
            acl.inPageMenu = {}
            Object.keys(AllowList.inPageMenu).map((m, n) => {
                if (acl.inPageMenu[m] === undefined) {
                    acl.inPageMenu[m] = []
                }
                AllowList.inPageMenu[m].map((a, b) => {
                    if (a.allowed.indexOf(ACL[v].allowed) >= 0) {
                        acl.inPageMenu[m].push(JSON.parse(JSON.stringify(a)))
                    }
                })
            })
        }
    })

    if (acl !== null && acl !== false) {
        acl.country = appConfig.country
    }

    return acl
}

export const processCustomerData = (response, callback) => {
    if (response.whoami) {
        const storeTokenLeft = {
            loginTokenSecondsLeft: response.whoami.loginTokenSecondsLeft,
            timestamp: new Date().getTime()
        }
        StoreAuth.tokenExpire = storeTokenLeft
        localStorage.setItem(`tokenExpire`, JSON.stringify(storeTokenLeft))
    }

    Object.keys(sessionStorage).map((v, k) => {
        if (/^menuGroup+?/.test(v)) {
            sessionStorage.removeItem(v)
        }
    })
    Object.keys(localStorage).map((v, k) => {
        if (/^dashboard+?/.test(v)) {
            localStorage.removeItem(v)
        }
    })

    // also save new picture to acquaintances feature
    const customerID = get(response.onself.ushop.profile, 'id.unicity')
    const acquaintances = StorageExtend.getFromCountry('login-recovery')

    if (isSomething(customerID)) {
        const currentRestoreData = get(acquaintances, customerID, {})
        if (isSomething(currentRestoreData)) {
            const sizes = get(
                response.onself.ushop.profile,
                'profilePicture.sizes',
                []
            )
            const newAvatar = get(
                sizes.find(item => item.size === '500x500'),
                'media',
                ''
            )
            StorageExtend.setToCountry(
                newAvatar,
                `login-recovery.${customerID}.avatar`
            )
        }
    }

    if (response.onself.ushop.profile.mainAddress.country === Code2.myanmar) {
        logout()
        callback({ pass: false })
        return false
    }

    if (
        isCountryVietnam() &&
        response.onself.ushop.profile.mainAddress.country !== Code2.vietnam
    ) {
        logout(false)
        callback({
            pass: false,
            error: { error_message: 'only vietnam' }
        })
        return false
    }

    if (response.token !== undefined && response.customer.href !== undefined) {
        store.facebookConnected = true
    }

    let type = response.onself.ushop.profile.type
    if (type === 'Employee') {
        if (
            response.onself.ushop.profile.employmentDetails !== undefined &&
            response.onself.ushop.profile.employmentDetails.employerName !== 'Unicity'
        ) {
            type = response.onself.ushop.profile.employmentDetails.employerName
        }
    }

    response.onself.ushop.profile.acl = getAcl(type, response.onself.ushop.profile.status)

    if (
        response.onself.ushop.profile.acl === false ||
        response.onself.ushop.profile.acl === null
    ) {
        callback({
            pass: false
        })
    } else {
        resetReferralStore()
        localStorage.removeItem('logoutDailogShown')

        recognizeCustomer(response, response.customer.href, response.token)

        const menu = response.menu

        makeCustomersInternational((error, response) => {
            if (error) console.error('makeCustomersInternational', error)

            checkOutStore.dToken = null
            if (
                window.location.pathname === '/singapore/d/pfp' ||
                window.location.pathname === '/singapore/d/ffp'
            ) {
                window.location.href = '/singapore'
                return false
            }

            /* if (window.location.pathname === `/${Country.getPath()}/enroll/payment-response/success`) {
                history.push('/')
            }

            if (window.location.pathname === `/${Country.getPath()}/checkout/payment-response/success`) {
                history.push('/')
            } */

            callback({ pass: true, menu: menu })
        })
    }
}


// export const login = (
//     username,
//     password,
//     callback,
//     customerToken,
//     customerHref,
//     history
// ) => {
//     if (customerToken === undefined && customerHref === undefined) {
//         StoreAuth.Login(username, password)
//             .then(() => {
//                 storeAPI.login.isRedirecting = true
//
//                 localStorage.setItem(
//                     'user',
//                     KakaoService.encode(JSON.stringify({ username, password }))
//                 )
//                 window.location.href = '/'
//             })
//             .catch(error => {
//                 callback(error)
//             })
//             .finally(() => {
//                 // storeAPI.login.isLoading = false
//             })
//     } else {
//         // processCustomerData(username, customerToken, customerHref, undefined, undefined, callback, history, response)
//     }
// }
export const login = (
    username,
    password,
    callback,
    customerToken,
    customerHref,
    // history
) => {
    return new Promise((resolve, reject) => {
        if (customerToken === undefined && customerHref === undefined) {
            StoreAuth.Login(username, password)
                .then(() => {
                    storeAPI.login.isRedirecting = true
    
                    localStorage.setItem(
                        'user',
                        KakaoService.encode(JSON.stringify({ username, password }))
                    )
                    resolve(true)
                })
                .catch(error => {
                    reject(error)
                })
        }
    })
}

export const logout = (redirect = true) => {
    storeAPI.login.isRedirecting = true

    StorageExtend.unsetFromPersonal('checkout-restore')

    StoreAuth.Logout()
    StoreMenu.Reset()

    localStorage.removeItem('dummy-user')

    if (redirect === true) {
        localStorage.removeItem('enroll_data')
        localStorage.removeItem('enroll_state')
        localStorage.removeItem('itemData')
        localStorage.removeItem('language')
        localStorage.removeItem('productData')
        localStorage.removeItem('products')
        localStorage.removeItem('footer-data')
        localStorage.removeItem('checkOutStore')
        localStorage.removeItem('storageEnrollCart')
        localStorage.removeItem('EnrollStorage')
        localStorage.removeItem('ShoppingStorage')
        localStorage.removeItem('notificationShown')

        logoutWebview()
        store.authPass = false

        window.location.href = `/`
    } else {
        // store.customerData = {}
        store.authPass = false
    }
}

export const logoutWebview = () => {
    // webview logout function only for react native webview browsers
    // if token expires or failed to fetch customer data using token & href => then signout
    if (store.isWebview) {
        localStorage.removeItem('fired')
        localStorage.removeItem('loggedIn')
        localStorage.removeItem('isWebview')
        window.ReactNativeWebView.postMessage(
            JSON.stringify({ data: {}, targetFunc: 'logout' })
        )
    }
}


export const tiket = (redirect = true) => {

    Confirm({
        title: '티켓구매',
        content: "티켓 신청 하시겠습니까? 신청 화면으로 이동 시 몇초가 소요 됩니다. ",
        okText: '예',
        cancelText: '아니오',
        onOk: async () => {
            GetGenealogyData(
                StoreAuth.id,
                true,
                StoreAuth.token,
                StoreCountry.country
                    ? StoreCountry.country.short
                    : getCountryCode3()
            ).then(response => {
                const { data } = response
                let profileData = data.ushop.genealogy.items[0].customer;
                let rank;
                let highestRankShort = get(profileData, 'cumulativeMetricsProfile.highestRankShort')


                if (highestRankShort) {
                    rank = highestRankShort
                } else {
                    $.ajax({
                        type: 'GET',
                        headers: { Authorization: 'Bearer ' + StoreAuth.GetToken() },
                        url:store.customerData.metricsProfileHistory.href,
                        success: function(result) {
                            rank = result.aggregate.cumulativeMetricsProfile.highestRankShort;
                        },
                        error: function(result) {
                            console.log('error!!!')
                            console.log(result)
                            return false;
                        }
                    })
                }

                let rankName;
                if(getFullRankName(rank).toLowerCase() === 'fo') {
                    rankName = language.listen(`rank_${getFullRankName(rank).toLowerCase()}`)
                } else {
                    rankName =dictionary(`${getFullRankName(rank).toLowerCase()}`)
                }

                console.log('>>>>>>>>>>>>>>>')
                console.log(rank);
                console.log(rankName);
                console.log('>>>>>>>>>>>>>>>')

                if(rankName === 'GD' || rankName === 'SD' || rankName === 'ED' || rankName === 'PD' || rankName === 'PS' || rankName === 'PR' || rankName === 'PDI'
                    || StoreAuth.id === '209415382' || StoreAuth.id === '15745082' || StoreAuth.id === '250762182' || StoreAuth.id === '250762582') {
                    let fullName = store.customerData.humanName['fullName@ko'] === undefined ? store.customerData.humanName['fullName'] : store.customerData.humanName['fullName@ko']

                    const divTiket = document.createElement('div')
                    divTiket.style = 'display: none;'
                    divTiket.innerHTML = `
                        <form method="POST" id="formTiket" name="formTiket" action="https://www.unicitykorea.co.kr/tikets/tikets.php">
                        <input type="hidden" id="member_no" name="member_no" value="${StoreAuth.id}" autoComplete="off" />
                        <input type="hidden" id="member_name" name="member_name" value="${fullName}" autoComplete="off" />
                        <input type="hidden" id="phone" name="phone" value="${store.customerData.mobilePhone}" autoComplete="off" />
                        <input type="hidden" id="email" name="email" value="${store.customerData.email}" autoComplete="off" />
                        <input type="hidden" id="mybiz" name="mybiz" value="Y" autoComplete="off" />
                        </form>
                        `
                    document.body.appendChild(divTiket)
                    const formTiket = document.getElementById(
                        'formTiket'
                    )

                    if(storeBowser.isAppOSiOS() || storeBowser.isAppOSAndroid() || isMobile()){
                        formTiket.submit()
                    }else{
                        window.open('', 'formTiket')

                        formTiket.target = 'formTiket'
                        formTiket.submit()
                    }
                } else {
                    return Alert2({
                        title: '알림',
                        content: '티켓 구매는 골드디렉터 이상만 가능 합니다.'
                    })
                }
            });
        },
        onCancel: () => {}
    });
}